.modal-content {
	padding: 18px 40px;
	/* text-align: center; */
	width: 800px;
 }

 .notFound-container{
	text-align: center;
}
.variation-main{
}
