.seller-de .head-content-wrapper h4 {
  color: #000000;
  padding-bottom: 37px;
  font-family: "muli-bold";
  font-size: 22px;
  font-weight: 700;
}
.seller-de {
  background: transparent;
}
.seller-de .row:nth-child(2) {
  padding: 20px;
}
.seller-de .other-deatils-wrapper {
  padding-top: 20px;
  margin-top: 20px;
  border-top: 1px solid #d2d2d2;
}

.seller-de .row:first-child {
  padding: 0px;
}
.seller-de .product-detail-content-wrapper {
  padding-left: 23px;
  border-left: 1px solid #d2d2d2;
  padding: 45px 0px 60px 31px;
}
