.banner-ad-form {
  padding: 100px 0px;
}
.banner-ad-form .banner-form-wrapper {
  padding: 40px 20px;
  background: #f7f7f7;
  border-radius: 10px;
}
.banner-form-wrapper .banner-add-heading-wrapper {
  text-align: center;
  padding: 0px 0px 20px 0px;
}
.banner-form-wrapper .banner-add-heading-wrapper h3 {
  font-family: "muli-bold";
  font-size: 24px;
  font-weight: 700;
  color: #000000;
}
.banner-ad-form .banner-form-wrapper .per-day-wrapper {
  background: #e6e6e6;
  padding: 10px 19px;
  margin-bottom: 21px;
  border: 2px solid #f6f6f6;
  border-radius: 8px;
  box-shadow: 0px 2px 4px 0px #e6e6e6;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.banner-ad-form .banner-form-wrapper .per-day-wrapper span.property {
  font-family: "Open sans";
  font-size: 12px;
  font-weight: 600;
  color: #000000;
}
.banner-ad-form .banner-form-wrapper .per-day-wrapper span.value {
  font-family: "Open Sans";
  font-size: 21px;
  font-weight: 700;
  color: #000000;
}
.banner-ad-form h2 {
  font-family: "muli-bold";
  text-align: center;
  color: #000000;
  font-size: 45px;
  margin-bottom: 41px;
  font-weight: 700;
}
.banner-ad-form .banner-form-wrapper .form-group {
  margin: 0px 0px 32px 0px;
}
.banner-ad-form .banner-form-wrapper .form-group .col-lg-6 {
  padding-left: 0px;
}
.banner-ad-form .banner-form-wrapper .form-group label {
  font-family: "Open Sans";
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 11px;
}
.banner-ad-form .banner-form-wrapper .form-group input {
  height: 42px;
  border: 1px solid #1a1a1a;
  color: #000;
}
.banner-ad-form .banner-form-wrapper input[type="date"] {
	/* background-image: url("../img/date.png"); */
	background-position: 95% center;
	background-repeat: no-repeat;
}
.banner-ad-form .banner-form-wrapper .img-cont{
  width: 100%;
  height: 100px;
  overflow: hidden;
}
.banner-ad-form .banner-form-wrapper .img-cont img{
  width: 100%;
  height: 100px;
  /* object-fit: cover; */
}

/* .banner-ad-form .banner-form-wrapper input[type="date"]::-webkit-calendar-picker-indicator,
.banner-ad-form .banner-form-wrapper input[type="date"]::-webkit-inner-spin-button {
	display: none;
	-webkit-appearance: none;
} */
.banner-ad-form .banner-form-wrapper .form-group input::placeholder {
  color: #000;
  font-family: "Poppins";
  font-size: 11px;
  font-weight: 400;
}
.banner-form-wrapper .adver-wrapper {
  background: #e6e6e6;
  padding: 20px;
  border-radius: 7px;
  box-shadow: 0px 2px 4px 0px #e6e6e6;
  border: 1px solid #fff;
}
.banner-form-wrapper .adver-wrapper ul {
  list-style: none;
  padding: 0px;
  margin: 0px;
}
.banner-form-wrapper .adver-wrapper ul li {
  line-height: 2;
  display: flex;
  justify-content: space-between;
}
.banner-form-wrapper .adver-wrapper ul li span {
  font-size: 13px;
  color: #000000;
  font-weight: 600;
}
.banner-ad-form .banner-form-wrapper a.form-control {
  /* background-image: linear-gradient(
    to bottom,
    #71966d,
    #6a9166,
    #648b5f,
    #5d8659,
    #578152
  ); */
  color: #fff;
  margin-top: 20px;
  font-family: "muli-bold";
  text-transform: uppercase;
  font-size: 14px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
}
input#inputTag {
  display: none;
}
label {
  cursor: pointer;
}
#imageName {
  color: #71966d;
  display: block;
  margin: 10px 0px 0px 0px;
}
.banner-form-wrapper label.tag {
  display: block;
  width: 100%;
}

.banner-form-wrapper label.tag .fiel-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid;
  height: 42px;
  padding: 20px 10px;
  border-radius: 4px;
  background: #fff;
}

.banner-form-wrapper label.tag .fiel-wrapper i {
  background: #71966d;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: #ffff;
  border-radius: 4px;
  border: 1px solid #e3e3e3;
}
div#exampleModal .modal-content-wrapper {
  padding: 23px;
  text-align: center;
}

div#exampleModal .modal-content-wrapper .pop-content-wrapper {
  padding: 35px 25px;
}

div#exampleModal .modal-content-wrapper .pop-content-wrapper h3 {
  color: #3e7439;
  font-size: 30px;
  font-family: "muli-bold";
  font-weight: 700;
}

div#exampleModal .modal-content-wrapper .pop-content-wrapper p {
  color: #000000;
  font-size: 17px;
  text-transform: capitalize;
  margin-top: 11px;
  font-family: "muli-SemiBold";
}

div#exampleModal .modal-content-wrapper .pop-content-wrapper .btn {
  background: #fab528;
  padding: 7px 35px;
  color: #000;
  font-family: "muli-bold";
  font-size: 12px;
  font-weight: 700;
}
div#exampleModal .modal-content {
  border-radius: 13px;
  border: 0px;
}
