/* global css starts here */
* {
	font-family: "muli-reg";
	transition: all 0.2s ease-in-out;
	text-decoration: none !important;
}

a {
	text-decoration: none;
}
html{
	overflow-x: hidden!important;
}
body{
	overflow-x: hidden!important;
}
/* global css ends here */
/* Typography starts here */
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,500;0,600;0,700;1,300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

@font-face {
	font-family: "muli-reg";
	src: url("../fonts/Muli-Regular.ttf");
}

@font-face {
	font-family: "muli-bold";
	src: url("../fonts/Muli-Bold.ttf");
}

@font-face {
	font-family: "muli-SemiBold";
	src: url("../fonts/Muli-SemiBold.ttf");
	font-weight: 600;
}

h1 {
	font-family: "muli-bold";
	font-size: 36px;
	font-weight: 700;
}

h2 {
	font-family: "muli-bold";
	font-size: 30px;
	font-weight: 700;
}

h3 {
	font-family: "muli-bold";
	font-size: 25px;
	font-weight: 700;
}

h4 {
	font-family: "muli-bold";
	font-size: 18px;
	font-weight: 700;
}

h5 {
	font-family: "muli-bold";
	font-size: 16px;
	font-weight: 700;
}

p {
	font-family: "muli-reg";
	font-size: 16px;
	font-weight: 400;
}

.pagination-container {
	/* padding-top: 60px; */
}

.pagination-container ul li a {
	color: #000;
	margin: 0px 1px;
}

.pagination-container .page-item {
	padding: 0px 2px;
}

.pagination-container .page-item.active .page-link {
	background-color: #71966d !important;
	border: unset;
}

/* Typography ends here */
/* header starts here */
header#header .header-menu-icons-wrapper ul {
	padding: 0px;
	list-style: none;
	display: flex;
	align-items: center;
	justify-content: space-around;
}

header#header .header-menu-icons-wrapper ul li.icon-list {
	margin: 0px 10px;
	position: relative;
	flex: 0 0 10%;
}

header#header .header-menu-icons-wrapper ul li:last-child {
	flex: 0 0 30%;
}

header#header .header-menu-icons-wrapper ul li.icon-list .shopping-wrapper {
	display: flex;
	align-items: center;
}

header#header .header-menu-icons-wrapper ul li.phone-no {
	text-align: end;
	flex: 0 0 41%;
	margin-right: 41px;
}

header#header .header-menu-icons-wrapper ul li.phone-no h6 {
	color: #000;
	font-family: "Muli-bold";
	font-size: 15px;
	margin: 0;
}

.top-header-wrapper {
	border-bottom: 1px solid #e5e5e5;
}

header#header .main-header-wrapper {
	padding: 10px 0px;
}

header#header .main-header-wrapper .category-list-wrapper {
	position: absolute;
	z-index: 9;
	width: 217px;
	background: #fff;
}

header#header .main-header-wrapper .category-list-wrapper .accordion-item {
	border: 0px;
}

header#header
	.main-header-wrapper
	.category-list-wrapper
	.accordion-item
	ul.list-wrapper {
	list-style: none;
	padding: 0px;
}

header#header
	.main-header-wrapper
	.category-list-wrapper
	.accordion-item
	ul.list-wrapper
	li
	a {
	text-decoration: none;
	color: #8d8d8d;
	font-size: 12px;
	font-family: "muli-reg";
}

header#header
	.main-header-wrapper
	.category-list-wrapper
	.accordion-item
	button.accordion-button {
	color: #000;
	font-size: 14px;
	box-shadow: none;
}

header#header
	.main-header-wrapper
	.category-list-wrapper
	.accordion-item
	ul.list-wrapper
	li
	a {
	text-decoration: none;
	color: #8d8d8d;
	font-size: 12px;
	font-family: "muli-reg";
	cursor: pointer;
}

header#header
	.main-header-wrapper
	.category-list-wrapper
	.accordion-item
	.accordion-button:not(.collapsed) {
	background: transparent;
	border: 0px;
	box-shadow: none;
}

header#header
	.main-header-wrapper
	.category-list-wrapper
	.accordion-item
	.accordion-button:not(.collapsed)::after {
	filter: invert(1) brightness(1%);
}

header#header
	.main-header-wrapper
	.category-list-wrapper
	.accordion-item
	ul.list-wrapper {
	list-style: none;
	padding: 0px;
	/* height: 91px; */
	overflow-y: scroll;
}

header#header
	.main-header-wrapper
	.category-list-wrapper
	.accordion-item
	ul.list-wrapper::-webkit-scrollbar {
	background: #000;
	width: 0px;
}

header#header .main-header-wrapper .category-bar-wrapper {
	position: relative;
}

header#header .main-header-wrapper .category-bar-wrapper button.close {
	position: absolute;
	left: 6px;
	top: 13px;
	background: transparent;
	border: 0px;
	color: #fff;
}

header#header .header-menu-icons-wrapper ul li.phone-no span {
	color: #999999;
	font-family: "muli-reg";
}

header#header .header-menu-icons-wrapper ul li.icon-list i {
	color: #000;
	font-size: 23px;
}

header#header .header-menu-icons-wrapper ul li.icon-list a {
	text-decoration: none;
}

header#header .header-menu-icons-wrapper ul li.icon-list span.count {
	background: #ed9521;
	color: #fff;
	height: 18px;
	width: 18px;
	font-size: 10px;
	text-align: center;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 0px;
	left: 15px;
}

header#header .search-wrapper {
	display: flex;
}

header#header .search-wrapper select {
	flex: 0 0 24%;
	border: 0px;
	background: transparent;
	font-size: 12px;
	font-family: "muli-bold";
	font-weight: 800;
	box-shadow: none;
}

header#header .search-wrapper {
	background: #f3f3f3;
	padding: 7px 10px;
	border-radius: 6px;
	width: 74%;
	margin: 0px auto;
	margin-right: 0px;
	position: relative;
}
a.searchicon {
    position: absolute;
    right: 15px;
    top: 13px;
    color: #000;
}

header#header .search-wrapper .search-bar-wrapper {
	flex: 0 0 76%;
}

header#header .search-wrapper .search-bar-wrapper input {
	border: 0px;
	background: transparent;
	border-left: 1px solid #c5c5c5;
	border-radius: 0px;
	margin-left: 10px;
	font-size: 16px;
}

header#header .search-wrapper .search-bar-wrapper input{
	box-shadow: unset !important;
}

header#header .logo-wrapper {
	text-align: center;
}

header#header .header-menu-icons-wrapper {
	width: 70%;
	margin: 0 auto;
}

header#header .logo-wrapper {
	text-align: center;
}

header#header .header-menu-icons-wrapper {
	width: 70%;
	margin: 0 auto;
}

header#header
	.header-menu-icons-wrapper
	ul
	li.icon-list
	.shopping-wrapper
	.cart-content-wrapper {
	margin-left: 20px;
}

header#header
	.header-menu-icons-wrapper
	ul
	li.icon-list
	.shopping-wrapper
	.cart-content-wrapper
	h6 {
	margin: 0px;
	font-family: "muli-reg";
	color: #666666;
	font-size: 14px;
	font-weight: 400;
}

header#header
	.header-menu-icons-wrapper
	ul
	li.icon-list
	.shopping-wrapper
	.cart-content-wrapper
	span {
	font-family: "muli-bold";
	color: #000000;
	font-size: 16px;
}

header#header .menu-items-wrapper ul {
	padding: 0px;
	list-style: none;
	display: flex;
	padding-right: 75px;
	justify-content: end;
	margin: 0px;
}

header#header .menu-items-wrapper ul li.nav-item a {
	color: #000;
	font-family: muli-semibold;
	font-size: 16px;
	padding: 9px;
}

.main-header-wrapper .category-bar-wrapper button.category-btn {
	width: 100%;
	border: 0px;
	padding: 15px 0px;
	background: #71966d;
	color: #fff;
	font-family: "muli-bold";
	font-size: 14px;
}
@media (max-width: 1280px) {
	header#header .menu-items-wrapper ul li.nav-item a {
		padding: 9px 7px;
	}
}
@media (max-width: 1200px) {
	header#header .menu-items-wrapper ul li.nav-item a {
		font-family: "muli-semibold";
		color: #000;
		font-size: 16px;
		padding: 4px 12px;
	}

	header#header .menu-items-wrapper ul {
		padding-right: 0px;
		justify-content: space-between;
	}

	header#header .header-menu-icons-wrapper ul li.phone-no h6 {
		font-size: 14px;
	}

	header#header .header-menu-icons-wrapper ul li.phone-no span {
		font-size: 13px;
	}

	header#header .header-menu-icons-wrapper ul li.phone-no {
		margin-right: 21px;
	}

	header#header .search-wrapper {
		margin-right: 51px;
	}
}
@media (max-width: 1024px) {
	header#header .header-menu-icons-wrapper {
		width: 100%;
	}
	header#header .header-menu-icons-wrapper ul li:last-child {
		flex:unset;
	}
	header#header .menu-items-wrapper ul li.nav-item a {
		padding: 4px 7px;
	}
}
@media (max-width: 992px) {
	header#header .search-wrapper {
		width: 100%;
		margin: 15px auto;
	}
	header#header .header-menu-icons-wrapper ul li.phone-no {
		flex: unset;
	}
	header#header .menu-items-wrapper ul {
		padding: 0px 5px;
		justify-content: end;
	}

}
@media (max-width: 576px) {
	header#header .search-wrapper .search-bar-wrapper {
		flex: 0 0 66%;
	}
	header#header .menu-items-wrapper ul li.nav-item a {
		padding: 10px 5px;
		font-size: 12px;
	}
	.slick-dots li {
		width: 10px !important;
		height: 10px !important;
	}
	.slick-dots li button {
		width: 10px !important;
		height: 10px !important;
	}
	.slick-dots li button:before {
		line-height: 10px !important;
		width: 10px !important;
		height: 10px !important;
	}
}
@media (max-width: 300px) {
	header#header .search-wrapper select {
		font-size: 9px;
	}
	.form-control {
		font-size: 0.75rem;
	}
	header#header .search-wrapper .search-bar-wrapper input {
		margin-left: 0px;
		font-size: 12px;
		padding-right: 0px;
	}


}

/* header ends here */
/* banner starts here */
.banner .single-banner-slide-wrapper {
	/* padding: 147px 0px 164px 0px; */
	/* background: url("../img/banner-bg.png"); */
	/* background-size: 100% 100%; */

	background-size: 100% 100% !important;
	height: 70vh !important;
}
.for-banner-flex {
	display: flex;
	align-items: center;
	position: absolute;
	bottom: 0;
	top: 0;
}
.banner {
	overflow-x: hidden;
}

.banner .single-banner-slide-wrapper h5 {
	font-family: "muli-reg";
	font-size: 20px;
	color: #2a4356;
	font-weight: 400;
}

.banner .banner-slides-wrapper .slick-dots {
	right:0%;
	left: auto !important;
	bottom: 23px;
	position: absolute;
}

.banner .banner-slides-wrapper .slick-dots li.slick-active button::before {
	background: #71966d;
	border-radius: 4px;
	content: "";
	opacity: 1;
}

.banner .banner-slides-wrapper .slick-dots li button::before {
	background: #fff;
	border-radius: 4px;
	content: "";
	opacity: 1;
}

.banner .single-banner-slide-wrapper h1 {
	color: #2a4356;
	font-family: "muli-bold";
	font-size: 38px;
	font-weight: 700;
}

.banner .single-banner-slide-wrapper p {
	color: #3d596f;
	font-family: "muli-reg";
	font-size: 18px;
}

section.banner .single-banner-slide-wrapper a.btn {
	background: #ffffff;
	padding: 8px 25px;
	font-family: "muli-bold";
	color: #000;
	font-size: 15px;
}


@media (max-width: 576px) {
	.banner .single-banner-slide-wrapper h1 {
		font-size: 20px;
	}
}
/* banner ends here */
/* Browse category starts here */
.brwose-category {
	padding: 50px 0px 0px 0px;
}

.brwose-category .heading-wrapper {
	padding-bottom: 10px;
}

.brwose-category .heading-wrapper h3 {
	font-family: "muli-bold";
	color: #000000;
	font-size: 1;
	font-weight: 100;
}

.brwose-category .single-slide-wrapper {
	text-align: center;
}
.slider-img-wrapper {
	height: 190px;
}

.brwose-category .single-slide-wrapper .slide-content-wrapper h5 {
	font-family: "muli-bold";
	color: #000;
	text-align: center;
}
.brwose-category .single-slide-wrapper .slide-content-wrapper h5:hover {
	text-decoration: underline;
	color: #71966d;
}
.brwose-category .single-slide-wrapper figure {
	height: -webkit-fill-available;
	/* height: 300px; */
	width: 100%;
	overflow: hidden;
	padding: 14px;
}
.brwose-category .single-slide-wrapper figure img {
	/* height: 300px; */
	height: -webkit-fill-available;
	width: 100%;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}

.brwose-category .single-slide-wrapper img.img-fluid {
    margin: 0 auto;
    height: 150px;
}
.category-slides-wrapper .slick-prev {
	position: absolute;
	top: -13%;
	right: 4%;
	left: auto;
	background-color: #f3f3f3;
	border-radius: 4px;
	height: 26px;
	width: 26px;
}

.brwose-category .slick-next {
	background-color: #f3f3f3;
	top: -13%;
	right: 1%;
	border-radius: 4px;
	height: 26px;
	width: 26px;
}

.slick-arrow .slick-arrow {
	background: #f3f3f3;
}

.slick-arrow:hover {
	background: #71966d !important;
}

.brwose-category .slick-next::before {
	content: "\f105";
	font-family: "FontAwesome";
	color: #000;
	opacity: 1;
}

.brwose-category .slick-prev::before {
	content: "\f104";
	font-family: "FontAwesome";
	color: #000;
	opacity: 1;
}

/* Browse category ends here */
/* top savers starts here */
.top-savers {
	padding: 50px 0px 50px;
}

.top-savers .product-card-wrapper h5 {
	font-family: "muli-bold";
	color: #010101;
	font-size: 16px;
}

.product-card-wrapper .product-content-wrapper .counter-total-wrapper {
	display: flex;
	/* flex-wrap: wrap; */
	align-items: center;
	padding: 17px 0px;
	visibility: hidden;
}

.product-card-wrapper .product-img-wrapper {
	position: relative;
}

.product-card-wrapper .product-img-wrapper .save-wrapper {
	position: absolute;
	top: 0px;
	background: #fa532c;
	width: 76px;
	display: block;
	margin-left: 7px;
	margin-top: 7px;
	text-align: center;
	border-radius: 4px;
}

.product-card-wrapper .product-img-wrapper .save-wrapper h6 {
	color: #ffff;
	font-size: 12px;
	margin-top: 4px;
}

.top-savers .product-card-wrapper .product-content-wrapper h6 {
	color: #666666;
	font-family: "muli-reg";
	font-size: 12px;
}

.top-savers .product-card-wrapper h5 {
	font-family: "muli-bold";
	color: #010101;
	font-size: 16px;
}

.top-savers .product-card-wrapper span.ice {
	font-size: 14px;
	font-weight: 300;
}

.product-card-wrapper .product-content-wrapper .rating-wrapper ul {
	display: flex;
	list-style: none;
	padding: 0px;
}

.product-card-wrapper .product-content-wrapper .rating-wrapper ul li i {
	color: #ffb300;
	font-size: 10px;
}

.product-card-wrapper
	.product-content-wrapper
	.rating-wrapper
	ul
	li.total-rating
	span {
	display: inline-block;
	padding-left: 8px;
	font-family: "muli-reg";
	font-size: 11px;
	color: #999999;
}

.top-savers .product-card-wrapper .product-content-wrapper h4 {
	color: #669900;
	font-family: "muli-bold";
	font-size: 16px;
	font-weight: 700;
}

.product-card-wrapper .product-content-wrapper .progress-wrapper {
	background: #eeeeee;
	height: 7px;
	margin: 16px 0px;
}

.product-card-wrapper .product-content-wrapper .progress-wrapper .progress {
	height: inherit;
	width: 70%;
	background: #ed9521;
	border-radius: 0px;
}

.product-card-wrapper .product-content-wrapper .sold h6 {
	font-family: "muli-bold";
	font-size: 12px;
	color: #222222;
}

.product-card-wrapper .product-content-wrapper .btn {
	background: #ed9521;
	width: 100%;
	color: #fff;
	font-weight: 700;
	visibility: hidden;
}

.product-card-wrapper
	.product-content-wrapper
	.counter-total-wrapper
	input[type="number"] {
	flex: 0 0 25%;
	border: 1px solid #c9c9c9;
	width: 100px;
	border-radius: 3px;
	height: 38px;
	text-align: center;
	font-family: "muli-bold";
	color: #000;
}

.product-card-wrapper
	.product-content-wrapper
	.counter-total-wrapper
	input[type="number"]:focus-visible {
	outline: 0px;
}

.product-card-wrapper .product-content-wrapper .counter-total-wrapper h6 {
	flex: 0 0 70%;
	margin: 0px;
	margin-left: 20px;
	color: #000;
}

.product-card-wrapper .product-content-wrapper .counter-total-wrapper h6 span {
	color: #669900;
	font-weight: 700;
}

.product-card-wrapper {
	padding: 20px;
	border: 1px solid transparent;
	background: #fff;
}

.product-card-wrapper .product-img-wrapper figure {
	width: 100%;
	height: 150px;
	overflow: hidden;
}

.product-card-wrapper .product-img-wrapper img {
	width: 100%;
	height: 150px;
}

.product-card-wrapper:hover {
	border: 1px solid #c9c9c9;
	border-radius: 4px;
}

.product-card-wrapper:hover .counter-total-wrapper {
	visibility: visible;
}

.product-card-wrapper:hover a.btn {
	visibility: visible;
}
.col-lg-6 .product-card-wrapper .product-img-wrapper figure{
    width: 100%;
    height: 300px;
}
.col-lg-6 .product-card-wrapper .product-img-wrapper img {
    width: 100%;
    height: 300px;
}

.off-wrapper {
	background-image: url("../img/Base.png");
	padding: 57px 30px;
	background-size: cover;
}

.off-wrapper .heading-wrapper {
	margin-bottom: 30px;
}

.off-wrapper .heading-wrapper h3 {
	font-family: "muli-bold";
	text-align: center;
	font-size: 48px;
	color: #fa532c;
}

.off-wrapper .heading-wrapper h5 {
	text-align: center;
	font-family: "muli-reg";
	font-size: 20px;
	font-weight: 400;
}

.off-wrapper .off-form-wrapper input {
	height: 50px;
	margin: 9px 0px;
	border-radius: 6px;
	border: 1px solid transparent;
}

.off-wrapper .off-form-wrapper button.btn {
	margin: 0px auto;
	display: block;
	color: #fff;
	background: #ed9521;
	font-weight: 700;
	font-family: "muli-bold";
	margin-top: 22px;
}

.top-savers .saver-heading-wrapper {
	display: flex;
	padding-bottom: 0px;
	align-items: center;
}

.top-savers .saver-heading-wrapper h2 {
	font-family: "muli-bold";
	font-size: 30px;
	font-weight: 700;
	color: #000000;
}

.top-savers .saver-heading-wrapper span.btn {
	background: #fa532c;
	margin-left: 30px;
	color: #fff;
	font-family: "muli-bold";
	font-weight: 700;
	height: 42px;
	font-size: 14px;
	line-height: 2;
}

.top-savers .saver-heading-wrapper span.btn span.timer {
	margin-left: 14px;
}

.top-savers .slick-prev {
	position: absolute;
	top: -5%;
	right: 4%;
	left: auto;
	background-color: #f3f3f3;
	border-radius: 4px;
	height: 26px;
	width: 26px;
}

.top-savers .slick-next {
	background-color: #f3f3f3;
	top: -5%;
	right: 1%;
	border-radius: 4px;
	height: 26px;
	width: 26px;
}

.top-savers .slick-arrow .slick-arrow {
	background: #f3f3f3;
}

.top-savers .slick-arrow:hover {
	background: #71966d !important;
}

.top-savers .slick-next::before {
	content: "\f105";
	font-family: "FontAwesome";
	color: #000;
	opacity: 1;
}

.top-savers .slick-prev::before {
	content: "\f104";
	font-family: "FontAwesome";
	color: #000;
	opacity: 1;
}

/* top savers ends here */
/* Best seller starts here */
.best-seller {
	background: #f3f3f3;
	padding: 35px 0px 50px 0px;
}

.best-seller .best-heading-wrapper {
	display: flex;
	align-items: center;
}

.best-seller .best-heading-wrapper .tab-wrapper ul#myTab {
	border-bottom: 0px;
}

.best-seller
	.best-heading-wrapper
	.tab-wrapper
	ul#myTab
	li.nav-item
	button.active {
	border: 0px;
	background: transparent;
}

.best-seller .best-heading-wrapper .tab-wrapper ul#myTab li.nav-item button {
	color: #000000;
	font-size: 18px;
	font-family: "muli-bold";
}

.best-seller .best-heading-wrapper h2 {
	font-family: "muli-bold";
	color: #000000;
	font-size: 30px;
}

.best-seller .best-heading-wrapper .tab-wrapper ul#myTab {
	border-bottom: 0px;
	margin-left: 30px;
}

.best-seller .product-card-wrapper .product-content-wrapper h4 {
	color: #669900;
	font-family: "muli-bold";
	font-size: 16px;
	font-weight: 700;
}

.best-seller .product-card-wrapper .product-content-wrapper .btn {
	display: none;
}

.best-seller
	.product-card-wrapper
	.product-content-wrapper
	.counter-total-wrapper {
	display: none;
}
.product-card-wrapper .product-content-wrapper {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.best-seller .product-card-wrapper {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.best-seller .product-card-wrapper:hover a.btn {
	visibility: visible;
	display: block;
}

.best-seller .product-card-wrapper:hover .counter-total-wrapper {
	visibility: visible;
	display: flex;
}

.best-seller .product-card-wrapper h5 {
	font-family: "muli-bold";
	color: #010101;
	font-size: 16px;
}
.product-card-wrapper .product-content-wrapper {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.best-seller .product-card-wrapper span.ice {
	font-size: 12px;
	font-weight: 300;
}

.best-seller .slick-prev {
	position: absolute;
	top: -13%;
	right: 4%;
	left: auto;
	background-color: #ffffff;
	border-radius: 4px;
	height: 26px;
	width: 26px;
}

.best-seller .slick-next {
	background-color: #ffffff;
	top: -13%;
	right: 1%;
	border-radius: 4px;
	height: 26px;
	width: 26px;
}

.best-seller .slick-arrow .slick-arrow {
	background: #f3f3f3;
}

.best-seller .slick-arrow:hover {
	background: #71966d !important;
}

.best-seller .slick-next::before {
	content: "\f105";
	font-family: "FontAwesome";
	color: #000;
	opacity: 1;
}

.best-seller .slick-prev::before {
	content: "\f104";
	font-family: "FontAwesome";
	color: #000;
	opacity: 1;
}

/* RESPONSIVE STARTS HERE */
@media (max-width: 1366px) {
	.best-seller .product-card-wrapper span.ice {
		font-size: 10px;
		font-weight: 300;
	}

	.best-seller .best-heading-wrapper .tab-wrapper ul#myTab li.nav-item button {
		font-size: 15px;
		padding: 10px;
	}

	.best-seller .slick-prev {
		top: -18%;
	}

	.best-seller .slick-next {
		top: -18%;
	}
}
@media (max-width: 1024px) {
	.brwose-category {
		padding: 50px 0px;
	}
	.best-seller .best-heading-wrapper {
		display: block;
	}
	.best-seller .best-heading-wrapper .tab-wrapper ul#myTab {
		border-bottom: 0px;
		margin-left: 0px;
	}
	.best-seller .best-heading-wrapper .tab-wrapper ul#myTab li.nav-item button {
		font-size: 14px;
		padding: 10px 5px 0px;
	}
	.best-seller {
		padding: 50px 0px 50px 0px;
	}
	.product-card-wrapper {
		padding: 10px;
	}

}
@media (max-width: 992px) {
	.category-slides-wrapper .slick-prev {
		right: 6%;
	}
	.best-seller .slick-prev {
		right: 6%;
	}
	.top-savers .slick-prev {
		right: 6%;
	}
}
@media (max-width: 576px) {
	.category-slides-wrapper .slick-prev {
		right: 10%;
		top: -8%;
	}
	.category-slides-wrapper .slick-next {
		top: -8%;
		right: 2%;
	}
	.top-savers .slick-prev {
		right: 10%;
	}
	.best-seller .slick-prev {
		position: absolute;
		top: -8%;
		right: 10%;
	}
	.best-seller .slick-next {
		top: -8%;
	}
	.brwose-category .heading-wrapper h3 {
		font-size: 20px;
	}
	.top-savers .saver-heading-wrapper h2 {
		font-size: 20px;
	}
	.top-savers {
		padding: 0px;
	}
	.best-seller .best-heading-wrapper h2 {
		font-size: 20px;
	}
	.best-seller {
		padding: 0px 0px 50px 0px;
	}
}


/* RESPONSIVE ENDS HERE */
/* Best seller ends here */
/* footer starts here */
footer .footer-widget-wrapper {
	width: 60%;
}

footer {
	padding-top: 100px;
}

footer .copyright {
	margin-top: 30px;
	padding: 20px 0px 0px 0px;
}

footer .copyright .container {
	border-top: 1px solid #dcdcdc;
	padding: 24px 0px;
}

footer .heading-wrapper h5 {
	font-family: "muli-bold";
	font-size: 18px;
	font-weight: 700;
}

footer .footer-content-wrapper .heading-wrapper {
	margin-bottom: 38px;
}

footer .footer-links-wrapper ul {
	list-style: none;
	padding: 0px;
}

footer .footer-links-wrapper ul li {
	padding: 4px 0px;
}

footer .footer-links-wrapper ul li a {
	text-decoration: none;
	font-family: "muli-reg";
	color: #555555;
}

.footer-widget-wrapper .footer-content-wrapper p {
	font-family: "muli-reg";
	color: #555555;
	line-height: 1.6;
	padding-top: 20px;
}

/* footer ends here */
@media (max-width: 1024px) {
	footer {
		padding-top: 50px;
	}
	footer .footer-content-wrapper .heading-wrapper {
		margin-bottom: 15px;
	}

}
@media (max-width: 992px) {
	footer .footer-widget-wrapper {
		width: 100%;
	}
	footer .footer-content-wrapper .heading-wrapper {
		margin-bottom: 10px;
		margin-top: 10px;
	}
	footer .copyright {
		margin-top: 0px;
	}
}
@media (max-width: 567px) {
	.content-wrapper p {
		text-align: center;
		font-size: 13px;
	}	
	footer {
		padding-top: 50px;
	}
}



div#staticBackdrop .btn-close {
	opacity: 1;
	display: block;
	margin: 0px auto;
	margin-right: 10px;
	height: 8px;
	width: 10px;
	background-size: cover !important;
	padding: 0px;
	margin-top: 13px;
}

.rev .modal-dialog {
	max-width: 696px;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
}

.rev .modal-content {
	border-radius: 16px;
}

div#exampleModal .modal-dialog {
	height: 90vh;
	display: flex;
	align-items: center;
	justify-content: center;
}
