.return-detail-wrapper .app-btns-wrapper {
  display: flex;
  justify-content: end;
}
.return-detail-wrapper .app-btns-wrapper a.btn {
  margin: 0px 0px 0px 10px;
  padding: 5px 31px;
  color: #fff;
  background: #71966d;
  border: 1px solid #71966d;
  border-radius: 4px;
  font-family: "muli-SemiBold";
  font-size: 12px;
}
.return-detail-wrapper .app-btns-wrapper a.btn:hover {
  background: transparent;
  color: #71966d;
}
