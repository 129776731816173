.refund-order-wrapper {
  padding-top: 126px;
}
.refund-order-wrapper .heading-wrapper h4 {
  font-family: "muli-bold";
  font-size: 30px;
  color: #2a263d;
  font-weight: 700;
}
.refund-order-wrapper .heading-wrapper:first-child {
  padding-bottom: 30px;
}
.refund-order-wrapper .refund-fill-form-wrapper {
  background: #f9f9f9;
  padding: 30px;
}
.refund-product-form-wrapper .refund-product-wrapper .product-head-wrapper h5 {
  font-family: "muli-bold";
  font-size: 23px;
  color: #2a263d;
  font-weight: 700;
}
.refund-order-wrapper .refund-fill-form-wrapper .heading-wrapper h5 {
  font-family: "muli-bold";
  font-size: 23px;
  color: #2a263d;
  font-weight: 700;
}
.refund-product-form-wrapper .refund-product-wrapper {
  background: white;
  padding: 20px;
  border-radius: 7px;
  box-shadow: 2px 1px 11px -6px rgb(0 0 0 / 30%);
}
.refund-product-wrapper .product-refund-wrapper {
  display: flex;
  flex-wrap: wrap;
  padding-top: 18px;
  align-items: center;
}
.refund-product-wrapper .product-refund-wrapper .img-wrapper {
  flex: 0 0 15%;
}
.refund-product-wrapper .product-refund-wrapper .img-wrapper figure{
  height: 100px;
  width: 88px;
  overflow: hidden;
  border-radius: 8px;
}
.refund-product-wrapper .product-refund-wrapper .img-wrapper figure img{
  height: 100px;
  width: 88px;
}
.refund-product-wrapper .product-refund-wrapper .refund-content-wrapper {
  flex: 0 0 85%;
}
.refund-product-wrapper
  .product-refund-wrapper
  .refund-content-wrapper
  .head-date-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.refund-product-wrapper
  .product-refund-wrapper
  .refund-content-wrapper
  .head-date-wrapper
  h5 {
  color: #1f2c35;
  font-size: 18px;
  font-family: "muli-reg";
}
.refund-product-wrapper
  .product-refund-wrapper
  .refund-content-wrapper
  .head-date-wrapper
  h6 {
  font-family: "muli-SemiBold";
  font-size: 15px;
  font-weight: 600;
  color: #000;
  opacity: 0.5;
}
.refund-product-wrapper
  .product-refund-wrapper
  .refund-content-wrapper
  .size-wrapper {
  display: flex;
  justify-content: space-between;
}
.refund-product-wrapper
  .product-refund-wrapper
  .refund-content-wrapper
  .size-wrapper
  .size-ql-wrapper
  ul {
  padding: 0px;
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.refund-product-wrapper
  .product-refund-wrapper
  .refund-content-wrapper
  .size-wrapper
  .size-ql-wrapper
  ul
  li {
  font-size: 15px;
  font-family: "muli-SemiBold";
  color: #000;
  opacity: 0.5;
  padding: 0px 10px 0px 0px;
  border-right: 1px solid;
}
.refund-product-wrapper
  .product-refund-wrapper
  .refund-content-wrapper
  .size-wrapper
  .size-ql-wrapper
  ul
  li:last-child {
  padding-left: 12px;
  border: 0px;
}
.refund-product-wrapper
  .product-refund-wrapper
  .refund-content-wrapper
  .size-wrapper
  .size-date-wrapper
  span {
  font-family: "muli-SemiBold";
  font-size: 15px;
  font-weight: 600;
  color: #000;
  opacity: 0.5;
}
.refund-product-wrapper .product-refund-wrapper .price-wrapper h5 {
  color: #71966d;
  font-family: "muli-bold";
  font-weight: 700;
  font-size: 17px;
}
.refund-product-wrapper .product-refund-wrapper .img-wrapper img {
  height: 111px;
  width: 108px;
  object-fit: cover;
  border-radius: 10px;
}
.refund-form-wrapper .form-group {
  padding: 10px 0px;
}
.refund-form-wrapper label {
  font-family: "muli-bold";
  font-size: 20px;
  color: #2a263d;
  font-weight: 700;
  margin-bottom: 10px;
}
.refund-form-wrapper select.form-control {
  background: #eeeeee;
  border: 0px;
  height: 50px;
  font-family: "muli-SemiBold";
  font-size: 16px;
  color: #1f2c35;
  -webkit-appearance: auto;
}
.refund-form-wrapper input.form-control {
  background: #eeeeee;
  border: 0px;
  height: 50px;
  font-family: "muli-SemiBold";
  font-size: 16px;
  color: #1f2c35;
  -webkit-appearance: auto;
}
.refund-form-wrapper textarea.form-control {
  background: #eeeeee;
  border: 0px;
  font-family: "muli-SemiBold";
  font-size: 16px;
  color: #1f2c35;
}
.refund-form-wrapper textarea.form-control::placeholder {
  font-family: "muli-SemiBold";
  font-size: 16px;
  color: #1f2c35;
}
.refund-form-wrapper button.btn {
  width: 100%;
  background-image: linear-gradient(
    to bottom,
    #71966d,
    #6a9166,
    #648b5f,
    #5d8659,
    #578152
  );
  height: 50px;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: "muli-bold";
}

.refund-form-wrapper {
  padding-top: 10px;
  border-top: 1px solid #d4d4d4;
  margin-top: 11px;
}

.refund-form-wrapper .multi-imglist{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  list-style: none;
}
.refund-form-wrapper .multi-imglist li{
  margin: 10px;
}

.refund-form-wrapper .img-cont{
  height: 100px;
  width: 100px;
  overflow: hidden;
}
.refund-form-wrapper .img-cont img{
  height: 100px;
  width: 100px;
}
.refund-form-wrapper label.tag {
  display: block;
  width: 100%;
}

.refund-form-wrapper label.tag .fiel-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 0px solid;
  height: 50px;
  padding: 20px 10px;
  border-radius: 4px;
  background: #eee;
}

.refund-form-wrapper label.tag .fiel-wrapper i {
  background: #71966d;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: #ffff;
  border-radius: 4px;
  border: 1px solid #e3e3e3;
}
.refund-form-wrapper label.tag .fiel-wrapper span {
  font-family: "muli-SemiBold";
  font-size: 15px;
  font-weight: 600;
}
.refund-form-wrapper #imageName {
  color: #71966d;
  display: block;
  margin: 10px 0px 0px 0px;
  font-size: 13px;
}
