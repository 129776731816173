.banner-ads {
	background: #f9f9f9;
	padding: 22px;
	border-radius: 10px;
	margin-top: 129px;
}
.banner-ads .view-banner-ads-heading-wrapper {
	display: flex;
	justify-content: space-between;
	padding-bottom: 20px;
}
.banner-ads .view-banner-ads-heading-wrapper h2 {
	font-size: 35px;
	font-family: "muli-bold";
	color: #000000;
}

.banner-ads .view-banner-ads-heading-wrapper h4 {
	font-size: 18px;
	font-family: "muli-bold";
	color: #000000;
}
.banner-ads .view-card-wrapper {
	display: flex;
	flex-wrap: wrap;
	background: #e4e4e4;
	border-radius: 8px;
	padding: 29px;
	margin: 25px 0px;
}
.banner-ads .view-card-wrapper .ads-content-wrapper {
	flex: 0 0 70%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.banner-ads .view-card-wrapper .ads-img-wrapper {
	flex: 0 0 30%;
}

.banner-ads .view-card-wrapper .ads-content-wrapper .ads-date-list-wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-right: 20px;
}

.banner-ads
	.view-card-wrapper
	.ads-content-wrapper
	.ads-date-list-wrapper
	.single-date-wrapper {
	border-right: 1px solid #bbbbbb;
	flex: 0 0 33%;
	text-align: center;
}

.banner-ads
	.view-card-wrapper
	.ads-content-wrapper
	.ads-date-list-wrapper
	.single-date-wrapper:last-child {
	border: 0px;
}

.banner-ads
	.view-card-wrapper
	.ads-content-wrapper
	.ads-date-list-wrapper
	.single-date-wrapper:first-child {
	text-align: left !important;
}
.banner-ads
	.view-card-wrapper
	.ads-content-wrapper
	.ads-date-list-wrapper
	.single-date-wrapper:first-child
	.date {
	width: 100%;
}
.banner-ads
	.view-card-wrapper
	.ads-content-wrapper
	.ads-date-list-wrapper
	.single-date-wrapper
	h5 {
	font-family: "Open Sans";
	font-weight: 600;
	font-size: 16px;
	color: #000000;
	padding-bottom: 12px;
	white-space: nowrap;
}

.banner-ads
	.view-card-wrapper
	.ads-content-wrapper
	.ads-date-list-wrapper
	.single-date-wrapper
	h6 {
	font-family: "muli-reg";
	font-size: 11px;
	font-weight: 700;
	color: #000;
}

.view-card-wrapper .ads-brand-link-wrapper label {
	font-family: "Open Sans";
	font-size: 13px;
	font-weight: 600;
	color: #000000;
}
.view-card-wrapper .ads-brand-link-wrapper .fields-wrapper {
	display: flex;
	background: white;
	padding: 6px 18px;
	align-items: center;
	margin-right: 21px;
	justify-content: space-between;
	border-radius: 4px;
	margin-top: 0px;
}
.view-card-wrapper .ads-brand-link-wrapper .fields-wrapper input.form-control {
	background: transparent;
	border: 0px;
	flex: 0 0 81%;
}

.view-card-wrapper .ads-brand-link-wrapper .fields-wrapper button.btn {
	background: linear-gradient(
		to bottom,
		#71966d,
		#6a9166,
		#648b5f,
		#5d8659,
		#578152
	);
	color: #ffff;
	font-size: 13px;
	font-weight: 700;
	font-family: "muli-bold";
	white-space: nowrap;
}

.view-card-wrapper
	.ads-brand-link-wrapper
	.fields-wrapper
	input.form-control::placeholder {
	font-family: "Poppins";
	font-size: 11px;
	color: #000;
}
.banner-ads .view-card-wrapper .ads-img-wrapper .expire-date-wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px;
	background: #ffbfbf;
	border-radius: 3px;
	border: 1px solid #9a3407;
}

.banner-ads .view-card-wrapper .ads-img-wrapper .expire-date-wrapper h6 {
	margin: 0px;
	color: #9a3407;
	font-size: 12px;
	font-family: "muli-bold";
	font-weight: 700;
}

.banner-ads .view-card-wrapper .ads-img-wrapper .ad-img {
	position: relative;
}

.banner-ads .view-card-wrapper .ads-img-wrapper .ad-img .btn-wrapper {
	position: absolute;
	top: 5px;
	right: 0px;
}

.banner-ads .view-card-wrapper .ads-img-wrapper .ad-img img {
	height: 134px;
	width: 100%;
	object-fit: cover;
	border-radius: 4px;
}

.banner-ads .view-card-wrapper .ads-img-wrapper .ad-img .btn-wrapper .edit-btn {
	border: 0px;
	background: #e4e4e4;
	padding: 4px 9px;
	font-size: 11px;
	font-family: "Poppins";
	border-radius: 5px;
	color: #212529;
}

.banner-ads .upload-btn-wrapper a.btn {
	background-image: linear-gradient(
		to bottom,
		#71966d,
		#6a9166,
		#648b5f,
		#5d8659,
		#578152
	);
	color: #fff;
	margin-top: 20px;
	font-family: "muli-bold";
	text-transform: uppercase;
	font-size: 14px;
	height: 44px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.banner-ads
	.view-card-wrapper
	.ads-content-wrapper
	.ads-date-list-wrapper
	.single-date-wrapper
	.date {
	text-align: left;
	width: 67%;
	margin: 0px auto;
}
.banner-ads
	.view-card-wrapper
	.ads-img-wrapper
	.ad-img
	.btn-wrapper
	button.delete {
	background: #e4e4e4;
	border: 0px;
	padding: 4px 9px;
	font-size: 11px;
	font-family: "Poppins";
	border-radius: 5px;
}

.banner-ads
	.view-card-wrapper
	.ads-img-wrapper
	.ad-img
	.btn-wrapper
	button.delete:hover {
	color: red;
}
/* responsive starts here */
@media (max-width: 1366px) {
	.view-card-wrapper
		.ads-brand-link-wrapper
		.fields-wrapper
		input.form-control {
		flex: 0 0 77%;
	}
}
/* responsive ends here */

@media (max-width: 767px) {
	.banner-ads .view-banner-ads-heading-wrapper h2 {
		font-size: 20px;
	}
	.banner-ads {
		background: #f9f9f9;
		padding: 22px;
		border-radius: 10px;
		margin-top: 30px !important;
	}
	.banner-ads .view-card-wrapper {
		display: block;
		background: #e4e4e4;
		border-radius: 8px;
		padding: 10px 15px;
		margin: 20px 0;
	}
	.banner-ads .view-card-wrapper .ads-content-wrapper {
		display: block;
	}
	.banner-ads .view-card-wrapper .ads-content-wrapper .ads-date-list-wrapper {
		display: block;
		padding-right: 0px;
	}
	.banner-ads
	.view-card-wrapper
	.ads-content-wrapper
	.ads-date-list-wrapper
	.single-date-wrapper
	.date {
    text-align: left;
    width: 100%;
    margin: 20px auto;
}
.banner-ads
	.view-card-wrapper
	.ads-content-wrapper
	.ads-date-list-wrapper
	.single-date-wrapper
	h5 {
    padding-bottom: 0px;
}
.banner-ads
	.view-card-wrapper
	.ads-content-wrapper
	.ads-date-list-wrapper
	.single-date-wrapper {
    border-right: 0;
}
.banner-ads
	.view-card-wrapper
	.ads-content-wrapper
	.ads-date-list-wrapper
	.single-date-wrapper:first-child
	.date {
    width: 100%;
    margin: 5px 0px 25px 0px;
}
.view-card-wrapper .ads-brand-link-wrapper .fields-wrapper {
    display: block;
	padding: 5px;
    margin: 10px 0px;
}
.view-card-wrapper .ads-brand-link-wrapper .fields-wrapper button.btn {
    width: 100%;
}
	
}

@media (max-width: 576px) {
	.banner-ads .view-banner-ads-heading-wrapper h2 {
		font-size: 20px;
	}
	.banner-ads {
		background: #f9f9f9;
		padding: 22px;
		border-radius: 10px;
		margin-top: 30px !important;
	}
	.banner-ads .view-card-wrapper {
		display: block;
		background: #e4e4e4;
		border-radius: 8px;
		padding: 10px 15px;
		margin: 20px 0;
	}
	.banner-ads .view-card-wrapper .ads-content-wrapper {
		display: block;
	}
	.banner-ads .view-card-wrapper .ads-content-wrapper .ads-date-list-wrapper {
		display: block;
		padding-right: 0px;
	}
	.banner-ads
	.view-card-wrapper
	.ads-content-wrapper
	.ads-date-list-wrapper
	.single-date-wrapper
	.date {
    text-align: left;
    width: 100%;
    margin: 20px auto;
}
.banner-ads
	.view-card-wrapper
	.ads-content-wrapper
	.ads-date-list-wrapper
	.single-date-wrapper
	h5 {
    padding-bottom: 0px;
}
.banner-ads
	.view-card-wrapper
	.ads-content-wrapper
	.ads-date-list-wrapper
	.single-date-wrapper {
    border-right: 0;
}
.banner-ads
	.view-card-wrapper
	.ads-content-wrapper
	.ads-date-list-wrapper
	.single-date-wrapper:first-child
	.date {
    width: 100%;
    margin: 5px 0px 25px 0px;
}
.view-card-wrapper .ads-brand-link-wrapper .fields-wrapper {
    display: block;
	padding: 5px;
    margin: 10px 0px;
}
.view-card-wrapper .ads-brand-link-wrapper .fields-wrapper button.btn {
    width: 100%;
}
	
}