.saved-payment-method .visa-card input#card-number {
	opacity: 1;
}
.checkout-payment
	.payment-details
	.saved-payment-method
	label.container
	span.checkmark
	.card-details
	.extra-btn {
	display: flex;
	align-items: center;
	flex: 0 0 10%;
}
.checkout-payment
	.payment-details
	.saved-payment-method
	label.container
	span.checkmark
	.card-details
	.visa-card {
	display: flex;
	align-items: center;
	padding-left: 0px !important;
	flex: 0 0 90%;
}
.checkout-payment
	.payment-details
	.saved-payment-method
	label.container
	span.checkmark
	.card-details
	.radio-btn
	span {
	background: #fff;
	width: 15px;
	height: 15px;
	position: absolute;
	right: 15px;
	left: unset;
	border-radius: 50%;
	border: 2px solid #fff;
	box-shadow: 0px 0px 0px 1px #f79b9d;
	top: 42.5px;
}
.checkout-payment .saved-payment-method .visa-card .input-box {
	display: flex;
	flex-direction: column;
	margin-left: 16px;
}
.checkout-payment
	.saved-payment-method
	.visa-card
	.input-box
	input#card-number {
	color: #343a40;
	font-size: 18px;
	font-weight: 600;
	background: transparent;
	margin-top: 4px;
	font-family: "muli-SemiBold";
}
.checkout-payment
	.saved-payment-method
	.visa-card
	.input-box
	input#card-number:active,
.checkout-payment
	.saved-payment-method
	.visa-card
	.input-box
	input#card-number:focus-visible {
	outline: none !important;
}
.checkout-payment
	.payment-details
	.saved-payment-method
	label.container
	span.checkmark
	.card-details
	.visa-card
	.input-box
	label {
	color: #979797;
	font-size: 10px;
	font-weight: 600;
	margin-top: -10px;
}
.checkout-payment
	.payment-details
	.saved-payment-method
	label.container
	input:checked
	~ span.checkmark
	.card-details {
	box-shadow: 0px 0px 0px 1px #c6c6c6;
	border-color: #fff;
}
.checkout-payment
	.payment-details
	.saved-payment-method
	label.container
	span.checkmark
	.card-details {
	display: flex;
	align-items: center;
	justify-content: space-between;
	border: 1px solid #c6c6c6;
	padding: 14px 20px;
	position: relative;
}
.checkout-payment
	.payment-details
	.saved-payment-method
	label.container
	span.checkmark
	.card-details
	.extra-btn
	i {
	position: relative;
	right: -42px;
	top: -10px;
}
.checkout-payment
	.payment-details
	.saved-payment-method
	label.container
	input:checked
	~ span.checkmark
	.card-details
	.radio-btn
	span {
	background: #fdb340;
	border: 2px solid #ffffff;
	outline: 2px solid #fdb340;
}
.checkout-payment .btn-wrapper button.btn {
	margin: 0px;
}
.checkout-payment .type-and-card-wrapper .btn-wrapper {
	margin: 0px;
}
.checkout-payment .type-and-card-wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.checkout-payment .heading h3 {
	color: #2a263d;
	font-family: "muli-bold";
	font-size: 30px;
	font-weight: 700;
}
.checkout-payment .payment-details:last-child .heading h3 {
	color: #2a263d;
	font-family: "muli-bold";
	font-size: 20px;
	font-weight: 700;
}
.checkout-payment .payment-details:first-child {
	border-bottom: 1px solid #c9c9c9;
	padding-bottom: 61px;
}
.checkout-payment .btn-wrapper button.btn {
	margin: 0px;
	padding: 10px 33px;
	font-weight: 600;
}
.checkout-payment {
	padding-top: 0px;
}
.checkout-payment .add-new-method .form-group {
	display: flex;
	border: 1px solid #c6c6c6;
	margin-bottom: 34px;
}
.checkout-payment .add-new-method .form-group input:first-child {
	flex: 0 0 70%;
	border: 0px;
}
.checkout-payment .add-new-method .form-group input:nth-child(2) {
	padding: 0px;
	flex: 0 0 15%;
	border: 0px;
}
.checkout-payment .add-new-method .form-group input:nth-child(3) {
	border: 0px;
	flex: 0 0 15%;
}
/* Saved Payment Css Start Here */
.checkout .payment-details .card-type {
	display: flex;
	max-width: 50%;
}
.checkout .payment-details .card-type input:checked ~ span.checkmark figure {
	box-shadow: 0px 0px 0px 1px #f9ca2b;
}
.checkout .payment-details .card-type span.checkmark figure {
	height: 35px;
	line-height: 35px;
	text-align: center;
	margin-right: 10px;
	width: 75px;
	overflow: hidden;
}
.checkout
	.payment-details
	.saved-payment-method
	label.container
	input[type="radio"] {
	opacity: 0;
}
.checkout .btn-wrapper button.btn {
	padding: 10px 49px;
	font-size: 17px;
	font-family: "Poppins";
	font-weight: 500;
	background: #fab528;
	border-radius: 0px;
	color: #0a0a0a;
}
.my-profile .checkout-payment .heading {
	padding-bottom: 10px;
	padding-top: 0px;
}
.my-profile .saved-payment-method .card-details h6.def {
	position: absolute;
	right: 7%;
	bottom: 3%;
	font-size: 15px;
	font-family: "muli-reg";
}
/* RESPONSIVE STARTS HERE */
@media (max-width: 1366px) {
	.checkout .btn-wrapper button.btn {
		padding: 10px 16px;
		font-size: 13px;
	}
	.checkout-payment
		.payment-details
		.saved-payment-method
		label.container
		span.checkmark
		.card-details
		.radio-btn
		span {
		right: 7px;
	}
}
/* RESPONSIVE END HERE */
