.my-profile .wishlis-table-wrapper thead {
  background: #f2f2f2;
  border-bottom: 0px;
}
.my-profile .wishlis-table-wrapper thead th {
  padding: 17px 0px;
  border: 0px;
  width: 10%;
}
.my-profile .wishlis-table-wrapper thead tr {
  border: 0px;
}
.my-profile .wishlist-wrapper tbody td {
  vertical-align: middle;
  padding: 0px 0px 0px 20px;
}
.my-profile .wishlist-wrapper tbody td.product-wrapper {
  /* display: flex;
  align-items: center;
  justify-content: space-between; */
  text-align: center;
}
.my-profile .wishlist-wrapper tbody td.product-wrapper .img-wrapper{
  width: 120px;
  height: 120px;
  overflow: hidden;
  margin-bottom: 10px;
  margin-top: 10px;
}
.my-profile .wishlist-wrapper tbody td.product-wrapper .img-wrapper img{
  width: 120px;
  height: 120px;
}
.my-profile .wishlist-wrapper tbody td.product-wrapper h5 {
  color: #1f2c35;
  font-family: "muli-reg";
  font-size: 15px;
  font-weight: 400;
}
.my-profile .wishlist-wrapper tbody td.cross button.cross-btn {
  border: 0px;
  background: transparent;
  color: #71966d;
  font-size: 22px;
}
.my-profile .wishlist-wrapper tbody td.unit {
  color: #000000;
  font-family: "muli-SemiBold";
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  width: 32%;
}
.my-profile .wishlist-wrapper tbody td.cart-btn {
  text-align: end;
}
.my-profile .wishlist-wrapper tbody td.cart-btn button.btn {
  background: #fab528;
  border-radius: 0px;
  font-family: "muli-bold";
  font-size: 14px;
  padding: 10px 27px;
  white-space: nowrap;
}
.my-profile .wishlist-wrapper tbody tr {
  border-bottom: 1px solid #999999;
}
.my-profile .wishlist-wrapper tbody td {
  border-bottom: 0px;
}
.my-profile .wishlist-wrapper tbody {
  border-top: 0px;
}
.my-profile .wishlist-wrapper tbody tr:last-child {
  border-bottom: 0px;
}
.my-profile .wishlist-wrapper {
  margin-top: 124px;
}
.my-profile .wishlist-wrapper .wishlist-heading-wrapper {
  padding-bottom: 23px;
}
.my-profile .wishlis-table-wrapper thead th.cross-col {
  width: 5%;
}
.my-profile .wishlist-wrapper tbody td.cross {
  width: 5%;
}
.my-profile .wishlis-table-wrapper thead th.btn-col {
  width: 6%;
}
.my-profile .wishlist-wrapper tbody td.cart-btn {
  width: 20%;
}


@media (max-width: 576px) {
  .my-profile .wishlis-table-wrapper thead th {
    width: auto;
    white-space: nowrap;
}
.my-profile .wishlist-wrapper tbody td.cross {
  width: auto;
}
.my-profile .wishlist-wrapper tbody td.unit {
  width: auto;
}
.my-profile .wishlist-wrapper tbody td.cart-btn {
  width: auto;
}
.my-profile .wishlist-wrapper tbody td {
  width: 150px !important;
}
}