.about_pageheader {
  padding: 50px 0px;
  text-align: center;
}
.about_pageheader .pageheader-content-wrapper h2 {
  font-family: "muli-bold";
  font-size: 36px;
  font-weight: 700;
  color: #2a4356;
}
.about_pageheader .pageheader-content-wrapper h5 {
  font-size: 18px;
  font-weight: 300;
  color: #2a4356;
  font-family: "muli-reg";
}
.about-sec {
  padding: 0px 0px 100px 0px;
}
.about-sec .content-wrapper h3 {
  font-family: "muli-bold";
  color: #2a4356;
  font-size: 36px;
}
.about-sec .content-wrapper p {
  font-family: "muli-bold";
  font-size: 22px;
  line-height: 2;
  color: #2a4356;
}
.about-sec .content-wrapper a.btn {
  background: #fab528;
  padding: 14px 50px;
  font-family: "muli-bold";
  font-size: 14px;
  text-transform: capitalize;
  font-weight: 700;
}
.we-do {
  background-image: url("../img/we-do-bg.png");
  background-size: 100%;
  padding: 100px 0px;
  background-repeat: no-repeat;
}
.we-do .we-color-wrapper {
  background: #71966d;
  padding: 45px;
  border-radius: 16px;
  position: relative;
}
.we-do h2.sec-heading {
  color: #2a4356;
  font-size: 40px;
  font-weight: 700;
  font-family: "muli-bold";
}
.we-do .we-color-wrapper .headings-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.we-do .we-color-wrapper .headings-wrapper h1 {
  font-size: 60px;
  color: #fff;
  opacity: 0.2;
  font-weight: 700;
  font-family: "muli-bold";
}
.we-do .we-color-wrapper .headings-wrapper h2 {
  color: #fff;
  font-size: 36px;
  font-weight: 700;
  font-family: "muli-bold";
}
.we-do .we-color-wrapper p {
  color: #fff;
  font-size: 22px;
  line-height: 1.8;
}
.we-do .we-color-wrapper::after {
  content: "";
  width: 97%;
  background: #fff;
  height: 5px;
  border-radius: 10px;
  position: absolute;
  bottom: 9px;
  left: 21px;
  right: 0px;
}
@media (max-width: 1600px) {
  .we-do {
    background-size: 108% 100%;
    background-position: center;
  }
}

@media (max-width: 768px) {
  .about-sec .content-wrapper {
    padding-right: 0 !important;
    margin-bottom: 30px;
    padding-left: 0 !important;
}
.about-sec .content-wrapper h3{
  text-align: center;
}
.about_pageheader {
  padding: 20px 0px;
  text-align: center;
}
}