.sign-in {
	padding: 100px 0px;
}
.form-check {
	position: relative;
}
.sign-in .form-check .form-check-input {
	float: left;
	margin-left: -1em;
}
.sign-in .sign-in-form-wrapper button.btn {
	width: 100%;
	background: #71966d;
	margin-bottom: 16px;
	height: 50px;
	color: #ffff;
	font-family: "muli-bold";
	font-size: 16px;
}
.sign-in .sign-in-form-wrapper .heading-wrapper h2 {
	color: #000000;
	font-size: 40px;
	font-family: "muli-bold";
	font-weight: 700;
}
.sign-in .sign-in-form-wrapper .heading-wrapper p {
	font-family: "muli-reg";
	color: #7f7f7f;
	font-size: 16px;
	font-weight: 400;
}
.sign-in .sign-in-form-wrapper .heading-wrapper {
	margin-bottom: 31px;
}
.sign-in .sign-in-form-wrapper .sign-form-wrapper .forgot-wrapper {
	text-align: end;
}
.sign-in .sign-in-form-wrapper .sign-form-wrapper label {
	font-family: "muli-SemiBold";
	font-size: 16px;
	font-weight: 600;
	margin-bottom: 13px;
}
.sign-in .sign-in-form-wrapper .sign-form-wrapper input.form-control {
	height: 50px;
	border: 1px solid #dadada;
	font-family: "muli-reg";
	font-size: 13px;
	font-weight: 300;
	margin-bottom: 14px;
}
.sign-in .sign-in-form-wrapper .sign-form-wrapper input.form-control:focus {
	box-shadow: none;
	outline: none;
	border: 1px solid #000;
}
.sign-in .sign-in-form-wrapper .sign-form-wrapper .forgot-wrapper a {
	color: #ed9521;
	font-family: "muli-SemiBold";
	font-size: 13px;
	font-weight: 600;
	text-decoration: underline !important;
}
.sign-in
	.sign-in-form-wrapper
	.sign-form-wrapper
	.form-check
	label.form-check-label {
	color: #8a8a8a;
	font-size: 14px;
	font-weight: 400;
	font-family: "muli-reg";
	margin-left: 10px;
}
.sign-in .form-check-input:checked[type="checkbox"] {
	background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%23fff%27 strokeLinecap=%27round%27 stroke-linejoin=%27round%27 strokeWidth=%273%27 d=%27M6 10l3 3l6-6%27/%3e%3c/svg%3e") !important;
}
.sign-form-wrapper .form-check-input:checked {
	background: #ed9521;
	border-radius: 0px;
	border-color: #e4a24b;
}
.sign-in .sign-in-form-wrapper .sign-up-tag-line-wrapper p {
	text-align: center;
	font-family: "muli-SemiBold";
	text-decoration: underline !important;
	color: #272727;
}
.sign-in .sign-in-form-wrapper .sign-up-tag-line-wrapper p a {
	color: #ed9521;
	font-family: "muli-SemiBold";
	font-size: 16px;
	margin-left: 10px;
	text-decoration: underline !important;
}
.sign-in .join-seller-wrapper .join-content-wrapper {
	text-align: center;
	width: 70%;
	margin: 0 auto;
}
.sign-in .join-seller-wrapper {
	border-left: 2px solid #71966d;
	padding: 22px 0px;
}
.sign-in .join-seller-wrapper .join-content-wrapper h3 {
	font-weight: 700;
	font-family: "muli-bold";
	font-size: 30px;
	color: #000000;
}
.sign-in .join-seller-wrapper .join-content-wrapper h3 span {
	color: #ed9521;
}
.sign-in .join-seller-wrapper .join-content-wrapper p {
	color: #7f7f7f;
	font-weight: 400;
	font-family: "muli-reg";
	padding: 10px 0px;
}
.sign-in .join-seller-wrapper .join-content-wrapper a.btn {
	background: #71966d;
	padding: 12px 40px;
	color: #fff;
	font-family: "muli-SemiBold";
	font-size: 14px;
}
.sign-in .sign-in-form-wrapper .otp-wrapper {
	display: flex;
}

.sign-in .sign-in-form-wrapper .otp-wrapper input {
	margin: 0px 7px 0px 0px;
	text-align: center;
}

.sign-in-form-wrapper button.btn-Resend {
	display: block;
	width: 100%;
	background: transparent;
	border: 0px;
	color: #ed9521;
	font-size: 13px;
	text-decoration: underline;
	font-weight: 600;
}
/* responsive starts here */
@media (max-width: 1366px) {
	.sign-in .sign-in-form-wrapper .heading-wrapper h2 {
		font-size: 37px;
	}
}

@media (max-width: 992px) {
	.sign-in {
		padding: 50px 0px;
	}
}
/* responsive ends here */
