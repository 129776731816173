.my-profile .save-address {
  margin-top: 40px;
}
.my-profile .box .deafult {
  display: flex;
  align-items: center;
  position: absolute;
  right: 20px;
  bottom: 10px;
}

.my-profile .deafult h5 {
  color: #1f2c35;
  font-size: 15px;
  margin: 0 20px 0 0px;
}
.my-profile label.container input:checked ~ span.checkmark span.dot {
  box-shadow: 0px 0px 0px 2px #9a3407;
  background: #9a3407;
}
.my-profile label.container .select-address .actions a {
  color: #000000;
  font-size: 20px;
  margin-left: 10px;
}
.my-profile label.container .select-address .actions {
  position: absolute;
  right: 20px;
  top: 30px;
}
.my-profile .tab-box label.container {
  padding: 0;
}
.my-profile .tab-box label.container .select-address {
  border: 2px solid #b5b5b5;
  padding: 20px 30px;
  position: relative;
  overflow: hidden;
  margin-bottom: 12px;
}
.my-profile label.container input:checked ~ span.checkmark .select-address {
  box-shadow: 0px 0px 0px 2px #c23127;
  border-color: #fff;
  border-radius: 0;
}
.my-profile .select-address .radio-btn span {
  width: 15px;
  height: 15px;
  background: #fff;
  position: absolute;
  left: 0;
  border-radius: 50%;
  border: 2px solid #fff;
  box-shadow: 0px 0px 0px 1px #c23127;
  top: 10px;
  margin: 20px 20px;
}
.my-profile
  label.container
  input:checked
  ~ span.checkmark
  .select-address
  .radio-btn
  span {
  background: #c23127;
}
.my-profile .select-address {
  position: relative;
}
.my-profile .select-address .address-detail h5 {
  width: 100%;
  display: flex;
  padding: 4px 0;
  align-items: center;
}
.my-profile .select-address .address-detail h5 span.property {
  font-size: 18px;
  color: #000;
  font-weight: 300;
}
.my-profile .select-address .address-detail h5 span.value {
  margin-left: 20px;
  font-weight: 300;
  color: #000000;
  font-size: 17px;
}

/* Second Tab Css Start Here */
.my-profile label.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.my-profile span.checkmark {
  position: unset;
}
.my-profile label.container {
  padding: 0;
}
.my-profile label.container .select-address {
  border: 2px solid #b5b5b5;
  padding: 12px 30px;
  position: relative;
  overflow: hidden;
  margin-bottom: 12px;
  cursor: pointer;
}
.my-profile label.container input:checked ~ span.checkmark .select-address {
  box-shadow: 0px 0px 0px 2px #9a3407;
  border-color: #fff;
  border-radius: 7px;
}
.my-profile .select-address .radio-btn span {
  width: 15px;
  height: 15px;
  background: #fff;
  position: absolute;
  left: 0;
  border-radius: 50%;
  border: 2px solid #fff;
  box-shadow: 0px 0px 0px 1px #c23127;
  top: 10px;
  margin: 20px 20px;
}
.my-profile
  label.container
  input:checked
  ~ span.checkmark
  .select-address
  .radio-btn
  span {
  background: #c23127;
}
.my-profile .select-address {
  position: relative;
}
/* .my-profile .tab-box .select-address .address-detail {
	padding-left: 20px;
	margin-left: 30px;
	border-left: 1px solid #7070705c;
} */
.my-profile .select-address .address-detail h5 {
  width: 100%;
  display: flex;
  padding: 4px 0;
  align-items: center;
  font-weight: 600;
  color: #aaaaaa;
}
.my-profile .select-address .address-detail h5 span.property {
  font-size: 17px;
  color: #000;
  font-weight: 500;
}
.my-profile .select-address .address-detail h5 span.value {
  margin-left: 20px;
  font-weight: 300;
  color: #000000;
  font-size: 17px;
}
.my-profile .select-address .button-group {
  position: absolute;
  bottom: 20px;
  right: 20px;
}
.my-profile .select-address .address-detail button.btn {
  background: #e7e7e7;
  color: #7d7d7d;
  font-size: 15px;
  font-weight: 500;
  text-transform: capitalize;
  opacity: 1;
  border-radius: 0;
  padding: 6px 20px;
}
.my-profile .select-address .address-detail button.lnk {
  background: transparent;
  border: 0;
  font-size: 15px;
  color: #1473e6;
  text-decoration: underline;
  margin-left: 10px;
}
.my-profile .add-new-address {
  padding-top: 20px;
  position: relative;
}
.my-profile .save-address {
  margin-top: 40px;
  border-top: 1px solid #acacac;
  padding-top: 30px;
}
.my-profile .box .deafult {
  display: flex;
  align-items: center;
  position: absolute;
  right: 20px;
  bottom: 10px;
}
.my-profile .deafult span.dot {
  width: 16px;
  height: 13px;
  background: #707070;
  display: block;
  border-radius: 50%;
  border: 2px solid #f4f4f4;
  box-shadow: 0px 0px 0px 2px #aaaaaa;
}
.my-profile .deafult h5 {
  color: #1f2c35;
  font-size: 15px;
  margin: 0 20px 0 0px;
}
.my-profilex label.container input:checked ~ span.checkmark span.dot {
  box-shadow: 0px 0px 0px 2px #c23127;
  background: #c23127;
}
.my-profile label.container .select-address .actions a {
  color: #000000;
  font-size: 20px;
  margin-left: 10px;
}
.my-profile label.container .select-address .actions {
  position: absolute;
  right: 20px;
  top: 30px;
}
.my-profile .deafult {
  display: flex;
  align-items: center;
  position: absolute;
  right: 20px;
  bottom: 10px;
}
.my-profile .save-address h3 {
  font-family: "muli-bold";
  font-size: 20px;
  font-weight: 700;
  color: #262626;
}
.my-profile .address-form-wrapper {
  margin-top: 124px;
}
.my-profile .address-form-wrapper button.btn {
  background: #fab528;
  border-radius: 0px;
  padding: 10px 45px;
  color: #000000;
  font-weight: 700;
  font-family: "muli-bold";
  font-size: 14px;
  margin-top: 20px;
  text-transform: uppercase;
}
.address-form-wrapper .form-group .col-lg-3,
.address-form-wrapper .form-group .col-lg-6 {
  padding-left: 0px;
}
/* Second Tab Css End Here */
.my-profile .save-address .heading {
  margin-bottom: 34px;
}
.address-form-wrapper h5 {
  color: #2a263d;
  font-family: "muli-reg";
  font-size: 26px;
  font-weight: 600;
}
.address-form-wrapper .heading {
  padding-bottom: 10px;
}
.address-form-wrapper .form-wrapper label {
  color: #433f40;
  font-family: "muli-reg";
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 15px;
}
.address-form-wrapper .form-wrapper input.form-control {
  border: 1px solid #c8c8c8;
  border-radius: 0px;
  color: #b4b4b4;
  font-weight: 500;
  filter: none;
  height: 45px;
}
.address-form-wrapper .form-wrapper textarea.form-control {
  border: 1px solid #c8c8c8;
  border-radius: 0px;
  color: #b4b4b4;
  font-weight: 500;
  filter: none;
}
.address-form-wrapper .form-group {
  margin: 20px 0px;
}

@media (max-width: 576px) {
  .my-profile label.container .select-address {
    padding: 10px;
}
.my-profile label.container .select-address .actions {
  right: 10px;
  top: 10px;
}
.my-profile .select-address .address-detail h5 span.property {
  font-size: 13px;
}
.my-profile .select-address .address-detail h5 span.value {
  font-size: 13px;
}
.my-profile .deafult h5 {
  font-size: 13px;
  margin: 0 10px 0 0px;
}
.my-profile .deafult {
  display: flex;
  align-items: center;
  position: unset;
}
.my-profile .address-form-wrapper {
  margin-top: 30px;
}
}