.accept-refund-wrapper {
	background: #f7f7f7;
	padding: 20px;
	position: relative;
	margin-top: 30px;
}
.accept-refund-wrapper .accept-form-wrapper {
	background: #fff;
	padding: 34px 30px;
	border-radius: 10px;
}
.accept-refund-wrapper .accept-form-wrapper .accept-head-wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-top: 20px;
}
.accept-refund-wrapper .accept-form-wrapper .accept-head-wrapper h3 {
	font-size: 25px;
	font-family: "muli-SemiBold";
	color: #000;
	font-weight: 600;
}

.accept-refund-wrapper
	.accept-form-wrapper
	.accept-head-wrapper
	.accept-date-wrapper {
	text-align: end;
}

.accept-refund-wrapper
	.accept-form-wrapper
	.accept-head-wrapper
	.accept-date-wrapper
	h6 {
	font-size: 15px;
	font-family: "muli-SemiBold";
	color: #000;
	opacity: 48%;
}
.accept-refund-wrapper
	.accept-form-wrapper
	.accept-head-wrapper
	.accept-date-wrapper
	span {
	font-size: 13px;
	font-family: "muli-SemiBold";
	color: #000;
	opacity: 48%;
}
.accept-refund-wrapper .accept-form-wrapper .schedule-head-wrapper {
	padding-top: 15px;
	margin-top: 15px;
	border-top: 1px solid #dedede;
}

.accept-refund-wrapper .accept-form-wrapper .schedule-head-wrapper h4 {
	font-size: 25px;
	font-family: "muli-SemiBold";
	color: #000;
	font-weight: 600;
}
.accept-refund-wrapper .accept-form-wrapper .schedule-form-wrapper label {
	font-size: 12px;
	font-family: "Open sans";
	color: #000;
	font-weight: 600;
}
.accept-schedule-wrapper .generate-wrapper {
	position: relative;
}
.accept-schedule-wrapper .generate-wrapper button.btn {
	position: absolute;
	top: 6px;
	right: 4px;
	height: 31px;
	padding: 0px 10px;
	font-size: 11px;
	font-family: "muli-bold";
	font-weight: 700;
	color: #fff;
	background-image: linear-gradient(
		to bottom,
		#71966d,
		#6a9166,
		#648b5f,
		#5d8659,
		#578152
	);
}
.accept-schedule-wrapper input {
	height: 42px;
	border: 1px solid #1a1a1a;
}

/* .accept-schedule-wrapper input[type="date"] {
	background-image: url("../img/date.png");
	background-position: 95% center;
	background-repeat: no-repeat;
} */
/* .accept-schedule-wrapper input[type="date"]::-webkit-calendar-picker-indicator,
.accept-schedule-wrapper input[type="date"]::-webkit-inner-spin-button {
	display: none;
	-webkit-appearance: none;
} */
.accept-schedule-wrapper button.btn.form-control {
	padding: 11px 10px;
	font-size: 13px;
	font-family: "Poppins";
	font-weight: 400;
	color: #fff;
	background-image: linear-gradient(
		to bottom,
		#71966d,
		#6a9166,
		#648b5f,
		#5d8659,
		#578152
	);
	border: 1px solid #1a1a1a;
}
.accept-refund-wrapper button.close {
	position: absolute;
	right: 30px;
	background: transparent;
	border: 0px;
	color: #60885f;
	padding: 0px;
	font-size: 20px;
	top: 22px;
}
.accept-schedule-wrapper textarea.form-control {
	border: 1px solid #1a1a1a;
	font-size: 12px;
	font-weight: 600;
	padding: 11px;
	color: #000000;
}
