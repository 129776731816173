.seller-info-wrapper {
  background: #f7f7f7;
  padding: 30px;
  margin-top: 30px;
}
.seller-info-wrapper .edit-head-wrapper h6 {
  font-family: "muli-SemiBold";
  font-size: 18px;
  font-weight: 600;
  color: #000000;
}
.seller-info-wrapper .profile-row-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  align-items: center;
}
.seller-info-wrapper .profile-row-wrapper .profile-img-wrappper img {
  height: 89px;
  width: 89px;
  object-fit: cover;
  border-radius: 50%;
  flex: 0 0 20%;
}
.seller-info-wrapper .profile-row-wrapper .profile-img-wrappper {
  display: flex;
  align-items: center;
}
.seller-info-wrapper
  .profile-row-wrapper
  .profile-img-wrappper
  .profile-upload-wrapper {
  flex: 0 0 80%;
  margin-left: 19px;
}
.seller-info-wrapper
  .profile-row-wrapper
  .profile-img-wrappper
  .profile-upload-wrapper
  h6 {
  color: #a4a4a4;
  font-size: 13px;
  font-family: "muli-reg";
  font-weight: 300;
  margin-bottom: 20px;
}
.seller-info-wrapper
  .profile-row-wrapper
  .profile-img-wrappper
  .profile-upload-wrapper
  button.btn {
  background: #71966d;
  color: #fff;
  font-size: 13px;
}
.seller-info-wrapper
  .profile-row-wrapper
  .profile-img-wrappper
  .profile-upload-wrapper
  button.btn input{
    position: absolute;
    opacity: 0;
  }
.seller-info-wrapper .profile-row-wrapper .processing-wrapper h5 {
  font-family: "muli-reg";
  font-size: 13px;
  color: #a4a4a4;
}
.seller-info-wrapper .profile-row-wrapper .processing-wrapper i {
  font-size: 13px;
  color: #a4a4a4;
}
.seller-info-wrapper .profile-row-wrapper .processing-wrapper {
  text-align: end;
}
.seller-info-wrapper .info-form-wrappe {
  border-top: 1px solid #d8d8d8;
  margin-top: 20px;
  padding-top: 26px;
}
.seller-info-wrapper .info-form-wrappe label {
  font-family: "muli-bold";
  font-size: 11px;
  font-weight: 700;
  color: #000000;
  text-transform: capitalize;
}
.seller-info-wrapper .info-form-wrappe input.form-control {
  border: 1px solid #000000;
  background: transparent;
  height: 42px;
}
.seller-info-wrapper .info-form-wrappe input.form-control::placeholder {
  color: #000000;
  font-family: "muli-reg";
  font-size: 11px;
}
.seller-info-wrapper .info-form-wrappe .form-check {
  position: relative;
}
.seller-info-wrapper .info-form-wrappe .form-check label {
  color: #777777;
  font-size: 14px;
}
.seller-info-wrapper .info-form-wrappe button.btn {
  background: #71966d;
  color: #fff;
  font-size: 13px;
  margin: 0px auto;
  margin-right: 0px;
}
.seller-info-wrapper .form-check label.form-check-label {
  margin-left: 10px;
}
.seller-info-wrapper .form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%23fff%27 strokeLinecap=%27round%27 stroke-linejoin=%27round%27 strokeWidth=%273%27 d=%27M6 10l3 3l6-6%27/%3e%3c/svg%3e") !important;
}
.seller-info-wrapper .form-check-input:checked {
  background: #ed9521;
  border-radius: 0px;
  border-color: #e4a24b;
}
.seller-info-wrapper .form-check .form-check-input {
  float: left;
  margin-left: -1em;
}
