.share-code-wrapper .refrelas-wrappe {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.share-code-wrapper .share-heading-wrapper {
  background: #f9f9f9;
  padding: 48px 30px 0px 30px;
  border-radius: 13px 13px 0px 0px;
  background-image: url("../img/apple.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.share-code-wrapper .share-heading-wrapper .headings-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.share-code-wrapper .share-heading-wrapper .headings-wrapper i {
  transform: rotateY(181deg);
  font-size: 58px;
  flex: 0 0 40%;
}

.share-code-wrapper .share-heading-wrapper .headings-wrapper h4 {
  flex: 0 0 60%;
  font-family: "muli-bold";
  font-size: 41px;
  font-weight: 700;
}
.copy-code-wrapper .copy-field-wrapper {
  background: #e3e3e3;
  padding: 10px;
  display: flex;
  border-radius: 4px;
  margin-top: 33px;
  margin-bottom: 39px;
}
.copy-code-wrapper .copy-field-wrapper input {
  background: transparent;
  border: 0px;
  padding: 0px 10px;
  font-size: 20px;
  color: #262b2e;
}
.copy-code-wrapper .copy-field-wrapper button {
  background: #71966d;
  padding: 8px 24px;
  color: #fff;
  font-family: "muli-bold";
  font-size: 21px;
  height: 52px;
}
.copy-code-wrapper .copy-field-wrapper input::placeholder {
  color: #262b2e;
  font-family: "muli-reg";
}
.share-code-wrapper .refrelas-wrappe .ref-stats-wrapper ul {
  list-style: none;
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.share-code-wrapper .refrelas-wrappe .ref-stats-wrapper ul li {
  border-right: 1px solid #000000;
  padding: 7px 43px;
  text-align: center;
}
.share-code-wrapper .refrelas-wrappe .ref-stats-wrapper ul li .stats h3 {
  font-family: "Poppins";
  font-size: 35px;
  font-weight: 600;
  color: #000;
}
.share-code-wrapper .refrelas-wrappe .ref-stats-wrapper ul li .stats p {
  font-family: "muli-bold";
  font-size: 22px;
  font-weight: 700;
  color: #000;
}
.share-code-wrapper .refrelas-wrappe .ref-head-wrapper h4 {
  font-family: "Poppins";
  font-size: 30px;
  font-weight: 600;
  color: #000000;
}
.share-code-wrapper .refrelas-wrappe .ref-head-wrapper {
  flex: 0 0 50%;
}
.share-code-wrapper .ref-stats-wrapper {
  flex: 0 0 45%;
}
.share-code-wrapper .refrelas-wrappe .ref-stats-wrapper ul li:last-child {
  border-right: 0px;
}
.share-code-wrapper {
  margin-top: 131px;
}
.share-code-wrapper .share-heading-wrapper .headings-wrapper h2 {
  font-size: 41px;
  font-weight: 700;
  font-family: "muli-bold";
  color: #000;
}
.share-code-wrapper .share-via-wrapper {
  background: #709570;
  padding: 17px 20px;
  border-radius: 0px 0px 13px 13px;
}
.share-code-wrapper .share-via-wrapper .share-icons-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.share-code-wrapper
  .share-via-wrapper
  .share-icons-wrapper
  .heading-wrapper
  h4 {
  font-family: "Poppins";
  font-size: 24px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 0px;
}
.share-code-wrapper
  .share-via-wrapper
  .share-icons-wrapper
  .social-icons-wrapper
  ul {
  list-style: none;
  padding: 0px;
  display: flex;
  margin: 0px;
}
.share-code-wrapper
  .share-via-wrapper
  .share-icons-wrapper
  .social-icons-wrapper
  ul
  li {
  margin: 0px 0px 0px 20px;
}
.share-code-wrapper
  .share-via-wrapper
  .share-icons-wrapper
  .social-icons-wrapper
  ul
  li
  a {
  text-decoration: none;
  color: #6f9473;
  height: 38px;
  width: 38px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 20px;
}


@media (max-width: 1024px) {
  .share-code-wrapper .share-heading-wrapper .headings-wrapper h2 {
    font-size: 30px;
  }
  .share-code-wrapper .refrelas-wrappe .ref-head-wrapper {
    flex: 0 0 35%;
  }
  .share-code-wrapper .refrelas-wrappe .ref-head-wrapper h4 {
    font-size: 26px;
}
}
@media (max-width: 576px) {
  .share-code-wrapper {
    margin-top: 30px;
}
.share-code-wrapper .share-heading-wrapper {
  padding: 30px 20px 0px 20px;
}
.share-code-wrapper .share-heading-wrapper .headings-wrapper h2 {
  font-size: 12px;
}
.share-code-wrapper .share-heading-wrapper .headings-wrapper i {
  font-size: 36px;
}
.copy-code-wrapper .copy-field-wrapper button {
  font-size: 16px;
}
.share-code-wrapper .refrelas-wrappe {
  display: block;
}
.share-code-wrapper .refrelas-wrappe .ref-stats-wrapper ul {
  list-style: none;
  padding: 0px;
  display: block;
}
.share-code-wrapper .refrelas-wrappe .ref-stats-wrapper ul li {
  border-right: 0;
  padding: 10px 0px;
  text-align: left;
}
.share-code-wrapper .share-via-wrapper .share-icons-wrapper {
  display: block;
}
.share-code-wrapper .share-via-wrapper {
  padding: 17px 10px;
}
.share-code-wrapper
  .share-via-wrapper
  .share-icons-wrapper
  .social-icons-wrapper
  ul
  li {
    margin: 10px 0px 0px 10px;
}
.copy-code-wrapper .copy-field-wrapper input {
  background: transparent;
  border: 0px;
  padding: 0px 5px;
  font-size: 16px;
}
}