.levels-sec .box-row {
	padding: 60px 0px;
	border-bottom: 1px solid #e5e5e5;
}
.levels-sec .box-row h2 {
	font-weight: 700 !important;
}
.levels-sec .box-row .level-description p {
	font-family: "muli-reg";
	font-size: 16px;
	color: #333;
	line-height: 28px;
}
.levels-sec .box-row .text-section h3 {
	font-size: 20px;
	font-family: "muli-bold";
	font-weight: 800;
}
.levels-sec .box-row .level-description {
	margin-bottom: 50px;
}
.levels-sec .box-row .level-description ul {
	margin-top: 29px;
}
.levels-sec .box-row .level-description ul li {
	font-family: "muli-reg";
	font-size: 16px;
	color: #333;
	line-height: 28px;
}
