/* Faq starts here */
.faq-sec {
  padding-bottom: 85px;
}
.faq-sec .content-wrapper {
  text-align: center;
}
.faq-sec .content-wrapper h6 {
  color: #25d0f0;
  font-family: "hanson";
  text-transform: uppercase;
  font-size: 19px;
  letter-spacing: 3px;
  font-weight: 700;
}
.faq-sec .content-wrapper h2 {
  font-family: "hanson";
  text-transform: uppercase;
  color: #fff;
  font-size: 32px;
}
.faq-sec .content-wrapper p {
  font-family: "Walkway";
  font-size: 15px;
  color: #b5bcbd;
}
.faq-sec .accordion-item {
  margin-bottom: 30px;
  border: 0px;
}
.faq-sec .accordion-item button.accordion-button {
  background-image: linear-gradient(
    to bottom,
    #71966d,
    #6a9166,
    #648b5f,
    #5d8659,
    #578152
  );
  border-radius: 5px;
  box-shadow: none !important;
  border: 0px;
  color: #fff;
  font-family: "muli-reg";
  font-size: 16px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.faq-sec .accordion-item .accordion-body {
  background: #f3f3f3;
  padding: 31px 25px;
  color: #7b7676;
  padding-left: 29px;
  font-size: 14px;
  position: relative;
  line-height: 1.9;
  width: 90%;
  margin: 0px auto;
  border-radius: 0px 0px 10px 10px;
  box-shadow: rgba(205, 208, 212, 0.2) 0px 8px 24px;
}
.faq-sec .accordion-button::after {
  content: "\f067";
  font-family: "FontAwesome";
  width: 0px;
  margin-right: 10px;
}
.accordion-button:not(.collapsed)::after {
  content: "\f068" !important;
  font-family: "FontAwesome";
  width: 0px;
  margin-right: 10px;
}
.faq-sec .accordion-item .accordion-body::before {
  content: "";
  width: 3px;
  height: 41%;
  display: block;
  background: #71966d;
  position: absolute;
  left: 0px;
  top: 25%;
  border-radius: 55px;
}
.faq-sec .accordion-item:last-of-type .accordion-button.collapsed {
  border-radius: 0px;
}
/* Faq ends here */
