.my-profile {
	padding: 100px 0px;
}
.sidebar-wrapper {
	border: 1px solid #dadada;
}
.sidebar-wrapper .user-details-wrapper {
	text-align: center;
	padding: 29px 0px;
	border-bottom: 1px solid #dadada;
	margin-top: -100px;
}
.sidebar-wrapper .user-details-wrapper .user-img-wrapper {
	position: relative;
}
.sidebar-wrapper .user-details-wrapper .user-img-wrapper {
	height: 134px;
	width: 134px;
	margin: 0px auto;
	border: 3px solid #9a3407;
	border-radius: 50%;
	overflow: hidden;
}

.sidebar-wrapper .user-details-wrapper .user-img-wrapper .change-img-wrapper {
	position: absolute;
	bottom: 0px;
	left: 0px;
	right: 0px;
}
.sidebar-wrapper
	.user-details-wrapper
	.user-img-wrapper
	.change-img-wrapper
	button {
	background: #fff;
	border: 0px;
	font-size: 10px;
	font-family: "muli-reg";
	color: #9a3407;
	width: 100%;
	display: block;
	padding: 3px 0px 10px 0px;
	text-decoration: underline !important;
}
.sidebar-wrapper
	.user-details-wrapper
	.user-img-wrapper
	.change-img-wrapper
	button input {
		position: absolute;
		opacity: 0;
	}

.sidebar-wrapper .user-details-wrapper .user-img-wrapper img {
	height: 133px;
	width: 133px;
	object-fit: cover;
	border-radius: 50%;
}
.sidebar-wrapper .user-name-wrapper h4 {
	font-family: "muli-bold";
	font-size: 25px;
	color: #2a263d;
	font-weight: 700;
	margin-top: 15px;
}

.sidebar-wrapper .navlink-wrapper ul {
	padding: 0px;
	list-style: none;
}
.sidebar-wrapper .navlink-wrapper {
	padding: 28px 30px;
}
.sidebar-wrapper .navlink-wrapper ul li a {
	font-family: "muli-bold";
	font-weight: 700;
	font-size: 18px;
	color: #9c9c9c;
}
.sidebar-wrapper .navlink-wrapper ul li a.side_active {
	color: #9a3407;
}
.my-profile .profile-heading-wrapper {
	padding-bottom: 114px;
}
.my-profile .profile-heading-wrapper h2 {
	font-family: "muli-bold";
	font-size: 43px;
	font-weight: 700;
	color: #2a263d;
}
.logout-wrapper {
	text-align: end;
}
.logout-wrapper button.logout-btn {
	background: #9a3407;
	border: 0px;
	font-size: 16px;
	padding: 10px 60px;
	color: #fff;
	text-transform: uppercase;
	font-family: "muli-bold";
	font-weight: 700;
}


@media (max-width: 1024px) {
	.my-profile {
		padding: 50px 0px;
	}
}