.seller-profile {
  padding: 50px 0px;
}

.seller-profile .profile-wrapper .profile-img-wrapper {
  flex: 0 0 30%;
}

.seller-profile .profile-wrapper {
  display: flex;
  align-items: center;
}

.seller-profile .profile-wrapper .profile-content-wrapper {
  margin-left: 21px;
  flex: 0 0 69%;
}

.seller-profile .profile-wrapper .profile-content-wrapper h6 span {
  font-family: "muli-SemiBold";
  font-weight: 600;
  font-size: 12px;
  margin-left: 6px;
}

.seller-profile .profile-wrapper .profile-content-wrapper h3 {
  font-family: "muli-bold";
  font-size: 29px;
  color: #363636;
}

.seller-profile .profile-wrapper .profile-content-wrapper h4 {
  color: #929292;
  font-family: "muli-reg";
  font-size: 18px;
}

.seller-profile .profile-wrapper .profile-content-wrapper .rating-wrapper ul {
  list-style: none;
  padding: 0px;
  display: flex;
}

.seller-profile
  .profile-wrapper
  .profile-content-wrapper
  .rating-wrapper
  ul
  li {
  margin: 0px 3px 0px 0px;
}

.seller-profile
  .profile-wrapper
  .profile-content-wrapper
  .rating-wrapper
  ul
  li
  i {
  background-image: linear-gradient(
    to bottom,
    #fdd134,
    #fcb820,
    #f99f10,
    #f5850a,
    #ef6a0f
  );
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

.seller-profile
  .profile-wrapper
  .profile-content-wrapper
  .rating-wrapper
  ul
  li
  span.total-rating {
  padding: 0px 10px;
  color: #929292;
  font-size: 14px;
  font-family: "muli-reg";
}

.seller-profile
  .profile-wrapper
  .profile-content-wrapper
  .rating-wrapper
  ul
  li
  span.total-reviews {
  /* padding: 0px 10px; */
  color: #929292;
  font-size: 14px;
  font-family: "muli-reg";
}

.seller-profile .row {
  background: #f3f3f3;
  padding: 55px 20px;
  border-radius: 10px;
}
.seller-profile .right-border {
  border-right: 1px solid #bbbbbb;
}
.seller-content-wrapper p {
  color: #707070;
  font-size: 14px;
  line-height: 1.8;
}

.seller-profile .seller-content-wrapper h2 {
  color: #363636;
  font-size: 29px;
  font-family: "muli-SemiBold";
  font-weight: 600;
}
.store-seller .product-card-wrapper:hover .counter-total-wrapper {
  display: none;
}

.store-seller .product-card-wrapper:hover .btn {
  display: none;
}

.store-seller .product-card-wrapper .counter-total-wrapper {
  display: none;
}

.store-seller .product-card-wrapper .btn {
  display: none;
}
.store-seller .product-card-wrapper {
  background: #f5f5f5;
  margin: 30px 10px;
  border-radius: 11px;
}
.store-seller .product-card-wrapper span.ice {
  color: #0099ff;
  font-size: 14px;
}

.store-seller .product-card-wrapper .rate-value {
  color: #090909;
  font-size: 14px;
}
