.subs-payment {
	padding: 100px 0px;
}
.subs-payment .heading-wrapper {
	text-align: center;
	margin-bottom: 20px;
}
.subs-payment .heading-wrapper h5 {
	font-size: 45px;
	font-weight: 700;
	font-family: "muli-bold";
}
.subs-payment .payment-card-wrapper {
	padding: 20px;
	background: #f7f7f7;
	border-radius: 0px;
}
.subs-payment .payment-card-wrapper .payment-heading-wrapper h6 {
	font-size: 18px;
	font-family: "muli-SemiBold";
}
.subs-payment .payment-card-wrapper .payment-form-wrapper label {
	margin-top: 0px;
	font-family: "Open Sans";
	font-size: 13px;
	font-weight: 600;
	margin-bottom: 18px;
}
.payment-card-wrapper .payment-form-wrapper ul.cards-list-wrapper {
	display: flex;
	list-style: none;
	padding: 0px;
}
.payment-card-wrapper .payment-form-wrapper ul.cards-list-wrapper label {
	position: relative;
}
.payment-card-wrapper .payment-form-wrapper ul.cards-list-wrapper input {
	position: absolute;
	opacity: 0;
	width: 100%;
	height: 100%;
}
.payment-card-wrapper
	.payment-form-wrapper
	ul.cards-list-wrapper
	input:checked
	~ img {
	border: 1px solid #71966d;
}

.payment-card-wrapper .payment-form-wrapper ul.cards-list-wrapper li {
	margin: 0px 28px 0px 0px;
}
.payment-card-wrapper .payment-form-wrapper ul.cards-list-wrapper li img {
	mix-blend-mode: darken;
	height: 41px;
	width: 63px;
	object-fit: contain;
	padding: 6px;
}
.payment-card-wrapper .payment-form-wrapper .form-control {
	border: 1px solid #1a1a1a;
	height: 44px;
}

.payment-card-wrapper .payment-form-wrapper .form-control::placeholder {
	font-size: 11px;
	color: #000000;
	font-weight: 600;
}
.payment-card-wrapper .payment-form-wrapper .form-group.row {
	margin-bottom: 16px;
}
.payment-card-wrapper .payment-form-wrapper .form-control.number {
	background-image: url("../img/card-number.png");
	background-repeat: no-repeat;
	background-position: 95% center;
}

.payment-card-wrapper .payment-form-wrapper .form-control.date {
	background-image: url("../img/date.png");
	background-repeat: no-repeat;
	background-position: 95% center;
}
.payment-card-wrapper .payment-form-wrapper .form-control.cvc {
	background-image: url("../img/key.png");
	background-repeat: no-repeat;
	background-position: 95% center;
}
.subs-payment .payment-card-wrapper .package-details-wrapper {
	background: #fff;
	padding: 16px 20px;
}

.subs-payment
	.payment-card-wrapper
	.package-details-wrapper
	.package-header-wrapper {
	display: flex;
	justify-content: space-between;
	border-bottom: 1px dashed #d2d2d2;
	padding: 10px 0px;
}

.subs-payment
	.payment-card-wrapper
	.package-details-wrapper
	.package-header-wrapper
	h5 {
	font-family: "muli-bold";
	font-size: 16px;
	margin: 0px;
	color: #000000;
}

.subs-payment
	.payment-card-wrapper
	.package-details-wrapper
	.package-header-wrapper
	.btn {
	background: #5e8659;
	color: #fff;
	font-family: "muli-reg";
	font-size: 12px;
	font-weight: 500;
}
.subs-payment
	.payment-card-wrapper
	.package-details-wrapper
	.package-content-wrapper {
	display: flex;
	justify-content: space-between;
	padding: 22px 0px;
}

.subs-payment
	.payment-card-wrapper
	.package-details-wrapper
	.package-content-wrapper
	h5 {
	font-size: 35px;
	font-family: "muli-SemiBold";
}
.subs-payment .payment-card-wrapper button.btn.form-submit {
	width: 100%;
	background: #71966d;
	margin-top: 26px;
	color: #fff;
	text-transform: uppercase;
	height: 52px;
	font-size: 14px;
	font-weight: 700;
	font-family: "muli-bold";
	border-radius: 8px;
}
.subs-payment .payment-card-wrapper .banner-heading-wrapper h5 {
	font-family: "Open Sans";
	text-align: center;
	background: #e6e6e6;
	padding: 10px 0px;
	font-size: 22px;
	font-weight: 700;
	width: 73%;
	margin: 0px auto;
	border-radius: 6px;
	margin-bottom: 30px;
}
.subs-payment
	.payment-card-wrapper
	.package-details-wrapper
	.package-content-wrapper.banner-content
	ul {
	list-style: none;
	padding: 0px;
	width: 100%;
	margin: 0px;
}

.subs-payment
	.payment-card-wrapper
	.package-details-wrapper
	.package-content-wrapper.banner-content
	ul
	li {
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.subs-payment
	.payment-card-wrapper
	.package-details-wrapper
	.package-content-wrapper.banner-content
	ul
	li
	h5 {
	font-size: 18px;
	flex: 0 0 50%;
	font-family: "Open Sans";
	font-weight: 600;
}

.subs-payment
	.payment-card-wrapper
	.package-details-wrapper
	.package-content-wrapper.banner-content
	ul
	li
	h5.value {
	text-align: end;
}
/* responsive starts here */
@media (max-width: 1366px) {
	.payment-card-wrapper .payment-form-wrapper .form-control {
		padding: 10px 4px;
	}
}
/* responssive ends here */
