.return-detail-wrapper .return-heading-wrapper h3 {
	font-family: "muli-bold";
	font-size: 30px;
	color: #2a263d;
	font-weight: 700;
}
.return-detail-wrapper .order-return-wrapper {
	background: #f9f9f9;
	padding: 30px;
}
.return-detail-wrapper {
	padding-top: 126px;
}
.return-detail-wrapper .return-heading-wrapper h3 {
	font-family: "muli-bold";
	font-size: 30px;
	color: #2a263d;
	font-weight: 700;
}
.return-detail-wrapper .order-return-wrapper {
	background: #f7f7f7;
	padding: 30px;
	margin-top: 36px;
}
.return-detail-wrapper {
	padding-top: 126px;
}
.order-return-wrapper .order-id-wrapper span {
	font-family: "muli-bold";
	font-size: 13px;
	font-weight: 700;
	color: #000000;
}
.order-return-wrapper .return-details-wrapper {
	margin-top: 10px;
	padding-bottom: 11px;
	border-bottom: 1px solid #bababa;
}

.order-return-wrapper .return-details-wrapper h5 {
	font-family: "muli-SemiBold";
	font-size: 20px;
	font-weight: 600;
}
.order-return-wrapper .product-refund-wrapper {
	display: flex;
	flex-wrap: wrap;
	padding-top: 18px;
	align-items: center;
	padding-bottom: 12px;
	border-bottom: 1px solid #bababa;
	margin-bottom: 18px;
}
.order-return-wrapper .product-refund-wrapper .img-wrapper {
	flex: 0 0 15%;
}

.order-return-wrapper .product-refund-wrapper .refund-content-wrapper {
	flex: 0 0 85%;
}

.order-return-wrapper .product-refund-wrapper .img-wrapper figure {
	height: 80px;
	width: 80px;
	border-radius: 10px;
	overflow: hidden;
}

.order-return-wrapper .product-refund-wrapper .img-wrapper img {
	height: 80px;
	width: 80px;
	object-fit: cover;
}

.order-return-wrapper
	.product-refund-wrapper
	.refund-content-wrapper
	.head-date-wrapper {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.order-return-wrapper
	.product-refund-wrapper
	.refund-content-wrapper
	.head-date-wrapper
	h5 {
	color: #1f2c35;
	font-size: 18px;
	font-family: "muli-reg";
}

.order-return-wrapper
	.product-refund-wrapper
	.refund-content-wrapper
	.head-date-wrapper
	h6 {
	font-family: "muli-SemiBold";
	font-size: 15px;
	font-weight: 600;
	color: #000;
	opacity: 0.5;
}

.order-return-wrapper
	.product-refund-wrapper
	.refund-content-wrapper
	.size-wrapper {
	display: flex;
	justify-content: space-between;
}

.order-return-wrapper
	.product-refund-wrapper
	.refund-content-wrapper
	.size-wrapper
	.size-ql-wrapper
	ul {
	padding: 0px;
	list-style: none;
	display: flex;
	justify-content: center;
	align-items: center;
}

.order-return-wrapper
	.product-refund-wrapper
	.refund-content-wrapper
	.size-wrapper
	.size-ql-wrapper
	ul
	li {
	font-size: 15px;
	font-family: "muli-SemiBold";
	color: #000;
	opacity: 0.5;
	padding: 0px 10px 0px 0px;
	border-right: 1px solid;
}

.order-return-wrapper
	.product-refund-wrapper
	.refund-content-wrapper
	.size-wrapper
	.size-ql-wrapper
	ul
	li:last-child {
	padding-left: 12px;
	border: 0px;
}

.order-return-wrapper
	.product-refund-wrapper
	.refund-content-wrapper
	.size-wrapper
	.size-date-wrapper
	span {
	font-family: "muli-SemiBold";
	font-size: 15px;
	font-weight: 600;
	color: #000;
	opacity: 0.5;
}

.order-return-wrapper
	.product-refund-wrapper
	.refund-content-wrapper
	.price-wrapper
	h5 {
	color: #71966d;
	font-family: "muli-bold";
	font-weight: 700;
	font-size: 17px;
}
.return-detail-wrapper .order-return-wrapper .retun-pro-headi-wrapper {
	padding-top: 25px;
}
.return-detail-wrapper .order-return-wrapper .retun-pro-headi-wrapper h5 {
	font-family: "muli-SemiBold";
	font-size: 20px;
	font-weight: 600;
	color: #000000;
}
.return-reason-wrapper .reason-heading-wrapper h5 {
	font-family: "muli-SemiBold";
	font-size: 20px;
	font-weight: 600;
	color: #000000;
}
.return-detail-wrapper .order-return-wrapper .retun-pro-headi-wrapper h5 {
	font-family: "muli-SemiBold";
	font-size: 20px;
	font-weight: 600;
	color: #000000;
}
.return-reason-wrapper .reason-heading-wrapper h5 {
	font-family: "muli-SemiBold";
	font-size: 20px;
	font-weight: 600;
	color: #000000;
}
.return-reason-wrapper input.form-control {
	background: #ececec;
	border: 0px;
	height: 50px;
	margin: 23px 0px;
	padding: 18px 21px;
	border-radius: 6px;
}
.return-picctures-wrapper .pictures-head-wrapper h5 {
	font-family: "muli-SemiBold";
	font-size: 20px;
	font-weight: 600;
	color: #000000;
}
.return-picctures-wrapper .pictures-wrapper {
	background: #ececec;
	padding: 13px 20px;
	display: flex;
	justify-content: space-around;
	border-radius: 10px;
	margin: 30px 0px;
}
.return-picctures-wrapper .pictures-wrapper img {
	height: 120px;
	width: 248px;
	object-fit: cover;
	border-radius: 10px;
}
.return-detail-wrapper .comment-head-wrapper h5 {
	font-family: "muli-SemiBold";
	font-size: 20px;
	font-weight: 600;
	color: #000000;
}
.comment-wrapper {
	background: #ececec;
	border: 0px;
	margin: 23px 0px;
	padding: 18px 21px;
	border-radius: 6px;

	font-family: "muli-SemiBold";
	font-size: 15px;
	font-weight: 600;
}
.comment-wrapper p {
	margin: 0px;
	color: #000;
	opacity: 0.5;
}
/* responsive starts here */
@media (max-width: 1366px) {
	.return-picctures-wrapper .pictures-wrapper img {
		width: 218px;
	}
}
/* responsive ends here */

@media (max-width: 576px) {
	.order-return-wrapper
	.product-refund-wrapper
	.refund-content-wrapper
	.head-date-wrapper {
    display: block;
}
.order-return-wrapper
	.product-refund-wrapper
	.refund-content-wrapper
	.size-wrapper {
    display: block;
}
.order-return-wrapper
	.product-refund-wrapper
	.refund-content-wrapper
	.size-wrapper
	.size-ql-wrapper
	ul {
    display: block;
}
.order-return-wrapper
	.product-refund-wrapper
	.refund-content-wrapper
	.size-wrapper
	.size-ql-wrapper
	ul
	li:last-child {
    padding-left: 0px;
}
.return-detail-wrapper {
    padding-top: 30px;
}
.return-picctures-wrapper .pictures-head-wrapper h5 {
    font-size: 18px;
}
.return-detail-wrapper .return-heading-wrapper h3 {
    font-size: 24px;
}
.order-return-wrapper
	.product-refund-wrapper
	.refund-content-wrapper
	.size-wrapper
	.size-ql-wrapper
	ul
	li {
    padding: 10px 10px 10px 0px;
    border-right: 0px;
}
}