.my-profile .paginations-wrapper ul {
    list-style: none;
    padding: 0px;
    display: flex;
}
.my-profile .paginations-wrapper ul li {
    margin: 0px 10px 0px 0px;
}
.my-profile .paginations-wrapper ul li.controls button {
    background: #FAC250;
    border: 0px;
    width: 37px;
    height: 37px;
    color: #fff;
    font-size: 21px;
}
.my-profile .paginations-wrapper ul li.number button {
    height: 37px;
    width: 37px;
    border: 3px solid #B6B6B6;
    background: transparent;
    font-size: 20px;
    font-family: 'muli-reg';
    color: #6E6E6E;
}
.my-profile .paginations-wrapper ul li.number.active button {
    border: 3px solid #FAC250;
    color: #FAC250;
}
.my-profile .paginations-wrapper {
    padding-top: 15px;
}

@media (max-width: 992px) {
    .my-profile .return-request-wrapper {
        padding-top: 30px;
    }
}

@media (max-width: 576px) {
    .my-profile .order-request-wrapper .order-detail-wrapper {
        padding: 20px;
    }
    .my-profile
  .order-request-wrapper
  .order-detail-wrapper
  .order-cards-wrapper
  .cards-wrapper {
    display: block;
}
.my-profile
  .order-request-wrapper
  .order-detail-wrapper
  .order-cards-wrapper
  .cards-wrapper
  .order-card-wrapper
  .order-img-wrapper
  figure {
    margin: 0px auto;
    height: 100px;
    width: 88px;
    overflow: hidden;
    border-radius: 8px;
}
}