.gmap_canvas {
  overflow: hidden;
  background: none !important;
  height: 500px;
  width: 100%;
}
.mapouter {
  position: relative;
  text-align: right;
  height: 500px;
  width: 100%;
}
.map-sec {
  overflow-x: hidden;
}
.contact-form {
  padding: 100px 0px;
}
.contact-form .contact-form-info-wrapper .heading-wrapper h4 {
  color: #000000;
  font-size: 24px;
  font-family: "muli-reg";
  font-weight: 400;
}
.contact-form .contact-form-info-wrapper .info h5 {
  color: #999999;
  font-size: 13px;
  font-family: "muli-bold";
  font-weight: 700;
}
.contact-form .contact-form-info-wrapper .info p {
  color: #000000;
  font-size: 16px;
  font-weight: 400;
}

.contact-form .contact-form-info-wrapper .info {
  padding-top: 30px;
}
.contact-form .contact-form-info-wrapper .heading-wrapper h5 {
  color: #000000;
  font-size: 24px;
  font-family: "muli-reg";
  font-weight: 400;
}
.contact-form .contact-form-info-wrapper .social-icon-wrapper {
  margin-top: 37px;
}
.contact-form .contact-form-info-wrapper .social-icon-wrapper ul {
  display: flex;
  list-style: none;
  padding: 0px;
  margin-top: 19px;
}
.contact-form .contact-form-info-wrapper .social-icon-wrapper ul li {
  margin: 0px 12px 0px 0px;
}
.contact-form .contact-form-info-wrapper .social-icon-wrapper ul li a {
  color: #fff;
  background: #71966d;
  width: 30px;
  display: block;
  height: 30px;
  border-radius: 50%;
  text-align: center;
  line-height: 1.9;
}
.contact-form .contact-form-info-wrapper {
  border-right: 1px solid #dedede;
  padding: 40px 180px 40px 0px;
}
.contact-heading-wrapper {
  padding-left: 80px;
}
.contact-form-wrapper {
  padding-left: 80px;
}
.contact-form .contact-heading-wrapper h5 {
  color: #000000;
  font-size: 24px;
  font-family: "muli-reg";
  font-weight: 400;
}
.contact-form .contact-heading-wrapper p {
  color: #666666;
  font-size: 18px;
  margin-top: 20px;
}
.contact-form .contact-heading-wrapper p span {
  color: red;
}
.contact-form .contact-form-wrapper input {
  margin-bottom: 14px;
  height: 46px;
  border: 1px solid #dadada;
  font-family: "muli-reg";
  /* text-transform: capitalize; */
  color: #999999;
}
.contact-form .contact-form-wrapper textarea {
  margin-bottom: 14px;
}
.contact-form .contact-form-wrapper button.btn {
  background: #fab528;
  color: #000;
  font-size: 14px;
  font-weight: 700;
  padding: 10px 30px;
  margin-top: 20px;
}


@media (max-width: 1024px) {
  .contact-form {
    padding: 30px 0px 60px 0px;
}
  .contact-form .contact-form-info-wrapper {
    padding: 40px 40px 40px 0px;
}
.contact-heading-wrapper {
  padding-left: 40px;
}
.contact-form-wrapper {
  padding-left: 40px;
}
}
@media (max-width: 992px) {
  .contact-form .contact-form-info-wrapper {
    padding: 40px 0px 40px 0px;
    border: 0px;
}
.contact-heading-wrapper {
  padding-left: 0px;
}
.contact-form-wrapper {
  padding-left: 0px;
}
}