.policy {
	padding: 100px 0px;
}

.policy .content-wrapper {
	text-align: center;
}

.policy .content-wrapper h2 {
	color: #2a4356;
	font-size: 36px !important;
	font-family: "muli-bold";
	font-weight: 700;
}

.policy .content-wrapper h6 {
	color: #2a4356;
	font-size: 18px !important;
	font-family: "muli-reg";
	margin-top: 20px;
}

.policy .content-wrapper p {
	font-family: "muli-SemiBold";
	color: #2a4356;
	font-size: 16px !important;
	line-height: 2;
	margin: 26px 0px;
}
