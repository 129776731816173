.my-profile .return-request-wrapper {
	padding-top: 121px;
}
.my-profile .return-request-wrapper .return-heading-wrapper h3 {
	font-family: "muli-bold";
	font-size: 30px;
	color: #2a263d;
	font-weight: 700;
}
.my-profile .return-request-wrapper .return-heading-wrapper {
	padding-bottom: 30px;
}
.my-profile .return-request-wrapper .return-table-wrapper table thead th {
	color: #2a263d;
	font-family: "muli-bold";
	font-size: 17px;
	font-weight: 700;
	text-align: center;
	padding: 0px 11px;
	text-transform: capitalize;
	border-bottom: 0px;
	white-space: nowrap;
}
.my-profile .return-request-wrapper .return-table-wrapper table thead tr {
	border: 0px !important;
}
.my-profile .return-request-wrapper .return-table-wrapper table tbody {
	border: 0px;
}
.my-profile .return-request-wrapper .return-table-wrapper table tbody {
	border: 0px;
}
.my-profile .return-request-wrapper .return-table-wrapper table tbody tr {
	/* border: 1px solid #c7c7c7; */
	vertical-align: middle;
}
.my-profile .return-request-wrapper .return-table-wrapper table thead {
	border: 0px;
}
.my-profile .return-request-wrapper .return-table-wrapper table tbody td span {
	color: #2a263d;
	font-family: "muli-SemiBold";
	font-size: 15px;
}
.my-profile .return-request-wrapper .return-table-wrapper table tbody td {
	text-align: center;
}
.my-profile
	.return-request-wrapper
	.return-table-wrapper
	table
	tbody
	td
	span.reject {
	color: #9a3407;
}
.my-profile
	.return-request-wrapper
	.return-table-wrapper
	table
	tbody
	td
	span.Accepted {
	color: #71966d;
}
.my-profile
	.return-request-wrapper
	.return-table-wrapper
	table
	tbody
	td
	span.paid {
	color: #00bc26;
}
.my-profile
	.return-request-wrapper
	.return-table-wrapper
	table
	tbody
	td
	button {
	color: #93ac73;
	text-decoration: underline !important;
	text-transform: uppercase;
	font-family: "muli-bold";
	font-size: 14px;
	font-weight: 700;
}
.my-profile .return-request-wrapper table {
	border-collapse: separate;
	border-spacing: 0 9px;
}
.my-profile .return-request-wrapper .return-table-wrapper table tbody tr td {
	border-top: 1px solid #c7c7c7;
}
.my-profile
	.return-request-wrapper
	.return-table-wrapper
	table
	tbody
	tr
	td:first-child {
	border-left: 1px solid #c7c7c7;
}
.my-profile
	.return-request-wrapper
	.return-table-wrapper
	table
	tbody
	tr
	td:last-child {
	border-right: 1px solid #c7c7c7;
	border-radius: 0px;
	border-bottom: 1px solid #c7c7c7;
}
.my-profile .return-request-wrapper .return-table-wrapper table tbody tr td {
	border-color: #c7c7c7;
}
/* RESPONSIVE STARTS HERE */
@media (max-width: 1366px) {
	.my-profile .return-request-wrapper .return-table-wrapper table thead th {
		font-size: 14px;
	}
	.my-profile
		.return-request-wrapper
		.return-table-wrapper
		table
		tbody
		td
		button {
		font-size: 14px;
		padding: 0px;
	}
}
/* RESPONSIVE ENDS HERE */


@media (max-width: 576px) {
	.my-profile .return-request-wrapper .return-table-wrapper table tbody tr td {
		width: 150px;
	}
}