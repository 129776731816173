.dashboard .shipmethods {
	display: flex;
	align-items: center;
}
.dashboard .heading_wrappers {
	display: flex;
	align-items: center;
}
.dashboard .heading_wrappers h4 {
	color: #000000;
	font-size: 20px;
	font-family: "Poppins";
	margin-right: 10px;
	margin-bottom: 0;
	font-weight: 500;
}
/* .dashboard .form-group.d-flex label {
    flex: 4;
  }
  .dashboard .form-group.d-flex input {
    flex: 8;
  } */
.dashboard .heading_wrappers h5 {
	color: #000000;
	font-size: 11px;
	font-family: "Poppins";
	font-weight: 600;
	margin: 0;
}
.dashboard .shipment_methods {
	flex: 1;
	margin-left: 45px;
}
.dashboard ul.shipmemntradios {
	padding: 0;
	margin: 0;
	list-style: none;
}
.dashboard ul.shipmemntradios label.container {
	padding: 0;
	margin: 0;
	display: unset;
	font-size: 11px;
	color: #000000;
	font-family: "Poppins";
}
.dashboard ul.shipmemntradios li {
	display: inline-block;
	margin-right: 10px;
}
.dashboard ul.shipmemntradios label.container input {
	opacity: 0;
}
.dashboard ul.shipmemntradios label.container span.checkmark:before {
	content: "";
	background: transparent;
	width: 13px;
	height: 13px;
	position: absolute;
	left: -20px;
	border-radius: 50%;
	border: 2px solid #fff;
	box-shadow: 0px 0px 0px 1px #939090;
	top: 1px;
}
.dashboard
	ul.shipmemntradios
	label.container
	input:checked
	~ span.checkmark:before {
	background: #07a8c4;
}
.dashboard .filters .title h6 {
	color: #000000;
	font-size: 11px;
	font-family: "Poppins";
	font-weight: 600;
}
.dashboard .filters .form-group label {
	color: #000000;
	font-size: 11px;
	font-family: "Poppins";
	font-weight: 600;
}
.dashboard .filters .form-group .form-control {
	width: 100%;
	font-weight: 400;
	border: 1px solid #000000;
	color: #000000;
	font-family: "Poppins";
	font-size: 11px;
	border-radius: 4px;
}
.dashboard .filters .form-group {
	flex: 1;
}
.dashboard .filters .input-group {
	align-items: center;
}
.dashboard .filters .input-group span.spacer {
	margin: 10px 10px 0px;
}
.sellers_orders {
	padding: 39px 20px;
	background: #f7f7f7;
	margin-top: 30px;
}
.sellers_orders h4 {
	font-family: "muli-SemiBold";
	font-size: 20px;
	font-weight: 600;
	color: #000000;
}
.sellers_orders .paginations_results {
	padding-top: 30px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}
.sellers_orders .paginations_results .showing_results p {
	color: #000000;
	font-size: 12px;
	font-family: "Poppins";
	margin: 0 0 15px;
	margin-bottom: 0;
}
.sellers_orders .paginations_results .showing_results {
	margin-right: 30px;
}
.sellers_orders .paginations_results ul.paginations {
	margin: 0;
	padding: 0;
	list-style: none;
	margin-left: 56px;
}
.sellers_orders .paginations_results ul.paginations li {
	display: inline-block;
}
.sellers_orders .paginations_results ul.paginations li.arrow {
	border: 1px solid #ababab;
	padding: 5px 15px;
	border-radius: 4px;
	margin: 0 5px;
}
.sellers_orders .paginations_results ul.paginations li.arrow i {
	color: #71966d;
	font-size: 21px;
}
.sellers_orders .paginations_results ul.paginations li.pagi {
	padding: 0 5px;
	font-size: 12px;
	color: #000000;
	font-family: "Poppins";
}
.add-upload-wrapper button.add-products {
	background: #fab528;
	padding: 9px 29px;
	color: #000;
	font-family: "muli-bold";
	font-size: 14px;
	font-weight: 500;
	border: 1px solid #fab528;
	border-radius: 5px;
	white-space: nowrap;
}
.add-upload-wrapper button.upload-products {
	background: #e8fbff;
	padding: 9px 55px;
	color: #07a8c4;
	font-family: "Poppins";
	font-size: 12px;
	font-weight: 500;
	border: 1px solid #07a8c4;
}
.dashboard .sellers_orders .table-resposnive th {
	font-size: 12px;
	font-weight: 700;
	font-family: "muli-bold";
	border-top: 0px;
	border-bottom: 1px solid #bababa;
	color: #000000;
}
.sellers_orders .heading_wrapper {
	padding: 0 26px;
	align-items: center;
}
.sellers_orders .heading_wrapper .add-upload-wrapper {
	flex: 0 0 21%;
}
.ref-or .sellers_orders .heading_wrapper {
	padding: 0px 26px 0px 47px;
}
.sellers_orders .table-resposnive td.orderid {
	color: #2dc215;
	font-family: "muli-reg";
	font-size: 11px;
	text-align: center;
	font-weight: 300;
	width: 8%;
}
.sellers_orders .table-resposnive td {
	font-family: "muli-reg";
	font-size: 11px;
	font-weight: 300;
	color: #000000;
	text-align: center;
	padding: 12px 7px;
}
.dashboard .sellers_orders .table-resposnive th {
	text-align: center;
}
.sellers_orders .button-wrap button {
	background: transparent;
	border: none;
}
.sellers_orders td.Boost button.boost-btn {
	color: #07a8c4;
	padding: 0px;
	background: transparent;
	font-family: "Poppins";
	text-decoration: underline;
	font-size: 12px;
}
.sellers_orders button.details-btn {
	color: #71966d;
	padding: 0px;
	background: transparent;
	font-family: "muli-reg";
	font-size: 11px;
}
.sellers_orders img.pro-table-img {
	border: 1px solid #71966d;
	border-radius: 50%;
	height: 25px;
	width: 25px;
	object-fit: cover;
}
.sellers_orders td {
	vertical-align: middle;
}
div#exampleModalCenter button.close {
	position: absolute;
	right: 4px;
	color: #07a8c4;
	opacity: 1;
	top: 4px;
	z-index: 99999;
	cursor: pointer;
}
div#exampleModalCenter .modal-body {
	padding: 48px;
}
div#exampleModalCenter h4 {
	color: #000000;
	font-size: 20px;
	font-family: "Poppins";
	font-weight: 600;
	margin-bottom: 20px;
	border-bottom: 1px solid #bcbcbc;
	padding-bottom: 10px;
}
div#exampleModalCenter .form-control {
	border: 1px solid;
	color: #707070;
	font-family: "Poppins";
	border-radius: 3px;
}
div#exampleModalCenter button.btn {
	display: block;
	background: #07a8c4;
	color: #fff;
	font-family: "Poppins";
	font-size: 15px;
	font-weight: 500;
	padding: 9px 26px;
	border: 1px solid;
	transition: all 0.3s ease-in-out;
}
div#exampleModalCenter button.btn:hover {
	outline: 1px solid #07a8c4;
}
div#exampleModalCenter [type="radio"]:checked,
div#exampleModalCenter [type="radio"]:not(:checked) {
	position: absolute;
	left: -9999px;
}
div#exampleModalCenter [type="radio"]:checked + label,
div#exampleModalCenter [type="radio"]:not(:checked) + label {
	position: relative;
	padding-left: 28px;
	cursor: pointer;
	line-height: 20px;
	display: inline-block;
	color: #666;
}
div#exampleModalCenter [type="radio"]:checked + label:before,
div#exampleModalCenter [type="radio"]:not(:checked) + label:before {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	width: 18px;
	height: 18px;
	border: 1px solid #ddd;
	border-radius: 100%;
	background: #fff;
}
div#exampleModalCenter [type="radio"]:checked + label:after,
div#exampleModalCenter [type="radio"]:not(:checked) + label:after {
	content: "";
	width: 10px;
	height: 10px;
	background: #07a8c4;
	position: absolute;
	top: 4px;
	left: 4px;
	border-radius: 100%;
	-webkit-transition: all 0.2s ease;
	transition: all 0.2s ease;
}
div#exampleModalCenter [type="radio"]:not(:checked) + label:after {
	opacity: 0;
	-webkit-transform: scale(0);
	transform: scale(0);
}
div#exampleModalCenter [type="radio"]:checked + label:after {
	opacity: 1;
	-webkit-transform: scale(1);
	transform: scale(1);
}
div#exampleModalCenter .form-check {
	padding: 0px 0px;
}
div#exampleModalCenter .form-check label.form-check-label {
	color: #07a8c4;
	font-family: "Poppins";
	font-size: 14px;
	font-weight: 500;
}
div#exampleModalCenter .modal-dialog {
	max-width: 400px;
	margin: 30px auto;
}

div#exampleModalCenter .form-group.d-flex {
	justify-content: space-between;
}

div#exampleModalCenter button.cancel {
	background: #e8fbff;
	padding: 9px 44px;
	color: #07a8c4;
	font-family: "Poppins";
	font-size: 12px;
	font-weight: 500;
	border: 1px solid #07a8c4;
}

div#exampleModalCenter button.pay-now {
	background: #07a8c4;
	padding: 9px 44px;
	color: #fff;
	font-family: "Poppins";
	font-size: 12px;
	font-weight: 500;
	border: 1px solid;
}
.sellers_orders .table-resposnive {
	overflow-x: scroll;
}
.sellers_orders .table-resposnive::-webkit-scrollbar {
	height: 4px;
	width: 4px;
	background: #e1e1e1;
}
.sellers_orders .table-resposnive::-webkit-scrollbar-thumb:horizontal {
	background: #71966d;
	border-radius: 10px;
}
.dashboard .sellers_orders .table-resposnive th.Status {
	width: 8%;
	text-align: center;
}
.dashboard .sellers_orders .table-resposnive th.billingname {
	width: 16%;
}
.dashboard .sellers_orders .table-resposnive th.amount {
	width: 13%;
}
.dashboard .sellers_orders .table-resposnive th.Image {
	width: 10%;
}
.dashboard .sellers_orders .table-resposnive th.shipmentstatus {
	width: 12%;
}
.dashboard .sellers_orders .table-resposnive th.Price {
	width: 11%;
}
.dashboard .sellers_orders .table-resposnive thead,
tbody tr {
	display: table;
	width: 1100px;
	table-layout: fixed;
}
.sellers_orders .table-resposnive td.billingname {
	width: 16%;
}
.sellers_orders .table-resposnive td.amount {
	width: 13%;
}
.sellers_orders .table-resposnive td.items {
	width: 10%;
}
.sellers_orders .table-resposnive td.shipmentstatus {
	width: 12%;
}
.sellers_orders .table-resposnive td.details {
	width: 11%;
}
.dashboard .sellers_orders .table-resposnive th.Stock {
	width: 12%;
}
.sellers_orders .table-resposnive td.Available {
	width: 10%;
}
.dashboard .sellers_orders .table-resposnive th.Featured {
	width: 10%;
}
.sellers_orders .table-resposnive td.Boost {
	width: 11%;
}
.dashboard .sellers_orders .table-resposnive th.Details {
	width: 11%;
}
.sellers_orders .table-resposnive td.Detailsu {
	width: 11%;
}
.dashboard .sellers_orders .table-resposnive th.Details {
	width: 11%;
}
.sellers_orders .table-resposnive tbody {
	border-top: 1px solid #bababa !important;
}
.dashboard .sellers_orders .table-resposnive th.Action {
	width: 11%;
}
.sellers_orders .table-resposnive td.Available span.Rejected {
	color: #9a3407;
	font-family: "muli-bold";
	font-size: 13px;
}
.sellers_orders .table-resposnive td.Available span.refunded,
.sellers_orders .table-resposnive td.Available span.request {
	color: #000;
	font-family: "muli-bold";
	font-size: 13px;
}
.refunr-ore .sellers_orders td.orderid {
	font-family: "muli-reg";
	color: #000000;
	font-size: 13px;
	text-align: center;
}
.refunr-ore .sellers_orders .table-resposnive td {
	font-size: 13px;
}
.dashboard .refunr-ore .sellers_orders .table-resposnive th {
	font-size: 13px;
	font-family: "muli-bold";
	font-weight: 700;
}
.refunr-ore .sellers_orders .table-resposnive th.billingname {
	width: 10%;
}
.refunr-ore .sellers_orders .table-resposnive td.billingname {
	width: 10%;
}
.refunr-ore .sellers_orders .table-resposnive th.amount {
	width: 9%;
}

.refunr-ore .sellers_orders .table-resposnive td.amount {
	width: 9%;
}
.refunr-ore .sellers_orders .table-resposnive th.Price {
	width: 6%;
}
.refunr-ore .sellers_orders .table-resposnive td.details {
	width: 6%;
}
.dashboard .refunr-ore .sellers_orders .table-resposnive th.Stock {
	width: 10%;
}
.refunr-ore .sellers_orders .table-resposnive th.Details {
	text-align: end;
}
.refunr-ore .sellers_orders .table-resposnive th.Details {
	text-align: end;
	width: 6%;
}
.refunr-ore .sellers_orders .table-resposnive td.Detailsu {
	text-align: end;
	width: 6%;
}
.dashboard .refunr-ore .sellers_orders .table-resposnive thead,
.dashboard .refunr-ore .sellers_orders tbody tr {
	width: 943px;
}
.sellers_orders .heading_wrapper h4 {
	flex: 0 0 30%;
}
.sellers_orders .heading_wrapper .input-group button.btn {
	background: #fab528;
	color: #000;
	height: 38px;
	border: 1px solid;
}
/* responsive starts here */
@media (max-width: 1366px) {
	.add-upload-wrapper button.add-products {
		padding: 9px 16px;
	}
}

@media (max-width: 1024px) {
	.sellers_orders .heading_wrapper {
		padding: 0;
	}
}
@media (max-width: 576px) {
	.sellers_orders .heading_wrapper {
		display: block !important;
	}
	.input-group {
		width: 102%;
	}
	.add-upload-wrapper button.add-products {
		width: 100%;
		margin: 15px 0px 0px 0px;
	}
	.sellers_orders .paginations_results {
		display: block;
	}
	.sellers_orders .paginations_results ul.paginations {
		margin-left: 0px;
	}
	.seller_sidebar .seller_profile .topbar {
		display: block;
	}
	.seller_sidebar .seller_profile .topbar ul.stars {
		margin: 0px;
	}
	.dashboard {
		padding: 50px 0px;
	}
}


/* responsive ends here */
