/* Seller Add Products Css Start Here */
.dashboard .products_adding {
	background: #b1b1b11f;
	padding: 50px 60px;
	position: relative;
	overflow: hidden;
}
.dashboard .products_adding .heading_wrapper {
	padding-bottom: 60px;
}
.dashboard .products_adding .heading_wrapper h3 {
	font-size: 22px;
	color: #000000;
	font-family: "Poppins";
}
.dashboard .products_adding label {
	color: #000000;
	font-size: 12px;
	color: #000000;
	font-family: "Poppins";
	font-weight: 600;
}
.dashboard .products_adding .form-control {
	border: 1px solid #bababa;
	color: #000000;
	font-family: "Poppins";
	font-size: 12px;
	background: transparent;
	height: 45px;
	opacity: 48%;
}
.dashboard .products_adding .form-check {
	position: relative;
	padding-left: 10px;
}
.dashboard .text-editor textarea.form-control {
	height: 153px !important;
}
.dashboard .products_adding .bottom_bar .form-check {
	flex: 0 0 71%;
}
.dashboard .products_adding .bottom_bar .button-group {
	display: flex;
	flex: 0 0 29%;
}
.dashboard .products_adding hr {
	background: #bababa;
	width: 100%;
	margin: 35px 20px 30px;
}
.dashboard .products_adding .checkboxex label.container {
	padding-left: 30px;
	position: relative;
}
.dashboard .products_adding .checkboxex label.container input {
	opacity: 0;
}
.dashboard .products_adding .checkboxex label.container span.checkmark:before {
	content: "";
	background: transparent;
	width: 17px;
	height: 17px;
	position: absolute;
	left: 0;
	border: 2px solid #f6f6f6;
	box-shadow: 0px 0px 0px 1px #000;
	top: 1px;
}
.dashboard
	.products_adding
	.checkboxex
	label.container
	input:checked
	~ span.checkmark:before {
	background: #000;
}
.dashboard .products_adding .radios_btn label {
	padding-left: 30px;
}
.dashboard .products_adding .radios_btn label {
	color: #07a8c4;
}
.dashboard .products_adding .radios_btn input {
	opacity: 0;
}
.dashboard .products_adding .radios_btn span.checkmark:before {
	content: "";
	background: transparent;
	width: 15px;
	height: 15px;
	position: absolute;
	left: 0;
	border-radius: 50%;
	border: 2px solid #f6f6f6;
	box-shadow: 0px 0px 0px 1px #07a8c4;
	top: 1px;
}
.dashboard .products_adding .radios_btn input:checked ~ span.checkmark:before {
	background: #07a8c4;
}
.dashboard .products_adding .bottom_bar .checkbox {
	display: flex;
	align-items: center;
}
.dashboard .products_adding .bottom_bar {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 40px;
}
.dashboard .products_adding .bottom_bar label {
	margin: 0 0 0 10px;
}
.dashboard .form-group {
	margin-bottom: 1rem;
}
.custom-file {
	position: relative;
	display: inline-block;
	max-width: 100%;
	height: 2.5rem;
	margin-bottom: 0;
	cursor: pointer;
}
.dashboard label {
	display: inline-block;
	margin-bottom: 0.5rem;
}
.custom-file-input {
	min-width: 14rem;
	max-width: 100%;
	height: 2.5rem;
	margin: 0;
	filter: alpha(opacity=0);
	opacity: 0;
}
.dashboard .products_adding .bottom_bar .button-group button.btn {
	background: #71966d;
	padding: 8px 31px;
	color: #fff;
	font-family: "muli-bold";
	font-size: 10px;
	cursor: pointer;
	border: 2px solid #71966d;
	transition: 0.6s;
	margin-left: 10px;
}
.dashboard .products_adding .bottom_bar .button-group button.btn:hover {
	color: #71966d;
	background-color: transparent;
}
.dashboard .products_adding .bottom_bar label.form-check-label {
	font-size: 11px;
	font-family: "muli-SemiBold";
	text-transform: capitalize;
	color: #000000;
}
.dashboard .products_adding .custom-file {
	width: 100%;
}
.dashboard .products_adding .multi-imglist {
    display: flex;
    justify-content: space-between;
    list-style: none;
    margin: 30px 0px;
    gap: 10px;
    padding: 0;
	flex-flow: wrap;
}
.multi-imglist li {
    position: relative;
}
.multi-imglist li a {
    position: absolute;
    right: -5px;
    top: -5px;
    background: #000;
    font-size: 10px;
    width: 20px;
    height: 20px;
    text-align: center;
    color: #fff;
    border-radius: 50%;
    line-height: 20px;
}
.dashboard .products_adding .custom-file ul li {
	list-style: none;
	margin: 4px;
}
.dashboard .products_adding .custom-file input[type="file"] {
	position: absolute;
	width: 100%;
	top: 33px;
	cursor: pointer;
}
.dashboard .products_adding .custom-file .form-control {
	background-image: url(../img/pin.png);
	background-repeat: no-repeat;
	background-position: right 10px center !important;
}
.dashboard .products_adding ul.file-list {
	padding: 0 0 0 13px;
	list-style: auto;
	color: #71966d;
	font-size: 12px;
	font-family: "Poppins";
	font-weight: 500;
	text-transform: capitalize;
	padding-top: 30px;
	margin: 0;
}
.dashboard .products_adding ul.file-list li {
	margin: 10px 0;
}
.dashboard .products_adding select.form-control {
	background-image: url("../img/select-arrow.png");
	background-repeat: no-repeat;
	background-position: 95% center;
}
.dashboard .products_adding ul.file-list span {
	position: absolute;
	right: 0rem;
	background: #71966d;
	width: 18px;
	text-align: center;
	height: 18px;
	border-radius: 50%;
	color: #ffffff;
	font-size: 10px;
	line-height: 19px;
}
.dashboard .products_adding .files_flex {
	position: relative;
	margin-bottom: 10px;
}
@media (max-width: 575px) {
	.dashboard .products_adding {
		padding: 30px 10px;
	}
	.dashboard .products_adding ul.file-list span.remove-list {
		right: 1rem;
	}
	.dashboard .products_adding .bottom_bar {
		margin-top: 20px;
		display: block;
	}
	.dashboard .products_adding .bottom_bar .button-group {
		margin-top: 20px;
	}
	.dashboard .products_adding .bottom_bar .button-group button.btn {
		width: 100%;
		margin-bottom: 5px;
	}
	.dashboard .products_adding .bottom_bar label {
		font-size: 9px;
	}
}
/* Seller Add Products Css End Here */
