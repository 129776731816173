/* Profile Sidebar Css Start Here */
.seller-tabs-list-wrapper ul li a.nav-item {
  opacity: 48%;
}
.seller-tabs-list-wrapper ul li a.nav-item.active {
  opacity: 1;
}
.seller_sidebar .heading_wrapper h4 {
  font-size: 25px;
  color: #000000;
  font-family: "Poppins";
}
.seller_sidebar .heading_wrapper p {
  font-size: 12px;
  color: #000000;
  font-weight: 400;
  font-family: "Poppins";
}
.seller_sidebar .heading_wrapper {
  padding-bottom: 40px;
}
.seller_sidebar .seller_profile {
  display: flex;
  align-items: center;
  background: #f7f7f7;
  padding: 20px 15px;
  margin-bottom: 30px;
}
.seller_sidebar .seller_profile .img_box figure {
  margin: 0;
  width: 80px;
  height: 80px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 10px;
}
.seller_sidebar .seller_profile .img_box figure img {
  width: 80px;
  height: 80px;
}
.seller_sidebar .seller_profile .topbar {
  display: flex;
  align-items: center;
}
.seller_sidebar .seller_profile .topbar h4 {
  margin: 0;
  font-size: 22px;
  color: #2a2a2a;
  font-family: "Poppins";
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.seller_sidebar .seller_profile .topbar ul.stars {
  margin: 0px 0px 0 15px;
  padding: 0;
  list-style: none;
}
.seller_sidebar .seller_profile .topbar ul.stars li {
  display: inline-block;
  margin: 0px 0px 0px 5px;
}
.seller_sidebar .seller_profile .topbar ul.stars i {
  color: #ffb800;
}
.seller_sidebar .seller_profile p.type {
  color: #2a2a2a;
  font-family: "Poppins";
  font-size: 11px;
  margin: 0;
}
.seller_sidebar .seller_profile .actions_btn a {
  font-size: 11px;
  color: #000000;
  font-family: "Poppins";
  font-weight: 500;
}
.seller_sidebar .seller_profile .actions_btn .spacer {
  margin: 0px 5px;
  text-align: center;
}
.seller_sidebar .sales_summary {
  background: #b1b1b11f;
  padding: 20px 20px;
}
.seller_sidebar .sales_summary .title h3 {
  font-size: 18px;
  color: #000000;
  padding-bottom: 30px;
  font-family: "Poppins";
}
.seller_sidebar .sales_summary .totals_sales {
  display: flex;
  align-items: center;
}
.seller_sidebar .sales_summary .sales {
  padding: 0 30px 0 0;
}
.seller_sidebar .sales_summary .week-sale {
  padding-right: 0;
  padding-left: 30px;
}
.seller_sidebar .sales_summary .totals_sales .sales span {
  font-size: 15px;
  color: #2a2a2a;
  font-family: "Poppins";
}
.seller_sidebar .sales_summary .totals_sales .sales .price {
  margin: 0;
  font-size: 35px;
  color: #2a2a2a;
  font-family: "Poppins";
  font-weight: 600;
}
.seller_sidebar .sales_summary .today-sale.sales {
  border-right: 1px solid #00000075;
}
@media (max-width: 575px) {
  .seller_sidebar .sales_summary .totals_sales {
    padding-bottom: 30px;
  }
  .seller_sidebar .sales_summary .today-sale.sales {
    padding-left: 0;
  }
  .seller_sidebar .sales_summary {
    margin-bottom: 25px;
  }
}
/* Profile Sidebar Css End Here */
/* seller dashboard starts here */
.dashboard {
  padding: 80px 0px;
}
.seller_sidebar .best-seller-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.seller_sidebar .best-seller-wrapper h5 {
  font-family: "muli-SemiBold";
  font-size: 12px;
  color: #2a2a2a;
  font-weight: 600;
  margin: 0px;
}
.seller_sidebar .best-seller-wrapper a {
  font-family: "muli-SemiBold";
  font-size: 12px;
  color: #2a2a2a;
  font-weight: 600;
  margin: 0px;
}
.seller_sidebar .change-pass-link a {
  font-family: "muli-SemiBold";
  font-size: 12px;
  color: #2a2a2a;
  font-weight: 600;
  margin: 0px;
}
.seller-tabs-list-wrapper ul {
  list-style: none;
  padding: 0px;
  display: flex;
  justify-content: space-between;
  margin: 0px;
}
.seller-tabs-list-wrapper {
  background: #71966d;
  padding: 10px 20px;
}
.seller-tabs-list-wrapper ul li a {
  color: #fff;
  font-family: "Poppins";
  font-size: 17px;
  font-weight: 500;
}
/* seller dashboard End here */
/* seller-dashboard css starts here */
.dashboard .dashboard-stats-wrapper {
  padding: 23px 20px;
  background: #fdf7eb;
}
.dashboard .recent-orders-wrapper tbody {
  border-top: 2px solid #bababa;
}
.dashboard-stats-wrapper .stats-and-number {
  display: flex;
  justify-content: space-between;
}
.dashboard .dashboard-stats-wrapper h6.item-label {
  color: #2a2a2a;
  font-family: "Poppins";
  font-size: 15px;
}
.dashboard .dashboard-stats-wrapper .stats-and-number h2 {
  color: #2a2a2a;
  font-size: 35px;
  font-weight: 600;
  margin-bottom: 0px;
  font-family: "Poppins";
}
.dashboard .recent-orders-wrapper {
  margin-top: 39px;
  background: #f7f7f7;
  padding: 21px;
}
.dashboard .recent-orders-wrapper h5 {
  margin-bottom: 32px;
  font-size: 18px;
  font-family: "muli-SemiBold";
  font-weight: 600;
}
.dashboard .cus .recent-orders-wrapper .content-wrapper {
  padding: 0px 0px 0px 59px;
}
.dashboard .recent-orders-wrapper table.table th {
  font-size: 13.5px;
  font-family: "Poppins";
  font-weight: 600;
  color: #000000;
  text-align: center;
  white-space: nowrap;
}
.dashboard .recent-orders-wrapper table.table th,
.dashboard .recent-orders-wrapper table.table td {
  text-align: center !important;
}
.dashboard .recent-orders-wrapper table.table td {
  font-family: "Poppins";
  font-size: 11.5px;
  font-weight: 300;
  color: #000000;
  vertical-align: middle;
}
.dashboard .recent-orders-wrapper table.table th.num {
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 300;
  color: #000000;
  padding: 15px 20px;
}
.dashboard .recent-orders-wrapper table.table td a {
  font-weight: 600;
  color: #ed9521;
}
.dashboard .button-wrapper {
  text-align: end;
}
.dashboard .button-wrapper a {
  font-weight: 600;
  color: #07a8c4;
  font-size: 12px;
}
.dashboard .rating-wrapper {
  margin-top: 40px;
  padding: 9px;
  background-color: #fbfbfb;
}
.dashboard .rating-wrapper h4 {
  color: #000000;
  font-size: 18px;
  font-family: "Poppins";
  font-weight: 500;
}

.dashboard .rating-wrapper .stars-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dashboard .rating-wrapper .stars-wrapper ul {
  padding: 0px;
  list-style: none;
  display: flex;
  align-items: center;
  margin: 0px;
}

.dashboard .rating-wrapper .stars-wrapper h6 {
  font-size: 11px;
  margin: 0px;
}

.dashboard .rating-wrapper .stars-wrapper ul li {
  margin: 0px 3px;
}

.dashboard .rating-wrapper .stars-wrapper ul li i {
  color: #ffb800;
}
.rating-wrapper .total-progress {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.rating-wrapper .total-progress .progress {
  flex: 10;
  margin-top: 12px;
  border: 1px solid #7f7f7f;
  background: transparent;
}

.rating-wrapper .total-progress .progress span {
  flex: 2;
}
.rating-wrapper .progress-bar-wrapper h4 {
  color: #000000;
  font-size: 11px;
  font-weight: 600;
  margin-bottom: 0px;
  margin-top: 7px;
}
.progress-bar-wrapper {
  margin-top: 30px;
}
.total-progress span.total-progre {
  color: #000000;
  font-size: 11px;
  text-align: end;
  margin-left: 14px;
  margin-top: 12px;
  font-family: "Poppins";
  font-weight: 300;
}
.total-progress span.total-progre h4 {
  margin-bottom: 0px !important;
}
.dashboard .total-progress .progress-bar {
  background: #07a8c4;
}
.dashboard .recent-orders-wrapper tbody {
  border-top: 2px solid #bababa;
}
.dashboard .customer-review-wrapper {
  background: #f6f6f6;
  margin-top: 30px;
  padding: 20px;
  border-radius: 6px;
}
.dashboard .customer-review-wrapper .heading-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px;
}
.dashboard .customer-review-wrapper .heading-wrapper h3 {
  font-size: 27px;
  color: #000000;
  font-family: "muli-reg";
}
.dashboard .customer-review-wrapper .heading-wrapper a {
  color: #92b26b;
  font-family: "muli-SemiBold";
  font-size: 11px;
  text-decoration: underline !important;
}
.customer-review-wrapper .ratings-wrap .single-rate {
  display: flex;
  justify-content: space-between;
  align-items: self-end;
  flex-wrap: wrap;
  margin: 12px 0px;
}
.customer-review-wrapper .ratings-wrap .single-rate .progress-wrapper {
  flex: 0 0 86%;
}
.customer-review-wrapper .ratings-wrap .single-rate .percent {
  flex: 0 0 9%;
}
.customer-review-wrapper .ratings-wrap .single-rate .progress-wrapper .pro {
  background: #d4d4d4;
  width: 100%;
  height: 7px;
  border-radius: 10px;
}
.customer-review-wrapper
  .ratings-wrap
  .single-rate
  .progress-wrapper
  .pro
  .per {
  background: #71966d;
  height: 7px;
  border-radius: 10px;
}
.customer-review-wrapper .ratings-wrap .single-rate .progress-wrapper h6 {
  font-family: "muli-bold";
  color: #000000;
  font-size: 15px;
}
.customer-review-wrapper .ratings-wrap .single-rate .percent h6 {
  font-family: "Poppins";
  color: #000000;
  font-size: 13px;
  margin: 0px;
}
.dashboard .btns-wrapper a.btn.btn-green {
  background: #71966d;
  height: 47px;
  color: #fff;
  font-family: "muli-bold";
  line-height: 2;
  padding: 6px 30px;
  font-size: 14px;
  font-weight: 700;
  flex: 0 0 65%;
  border-radius: 6px;
  white-space: nowrap;
}

.dashboard .btns-wrapper a.btn.btn-yellow {
  background: #ed9521;
  height: 47px;
  color: #fff;
  font-family: "muli-bold";
  line-height: 2;
  font-size: 14px;
  font-weight: 700;
  border-radius: 6px;
  flex: 0 0 33%;
  white-space: nowrap;
}
.dashboard .btns-wrapper {
  padding-top: 25px;
  display: flex;
  justify-content: space-between;
}
.dashboard .btns-wrapper a.btn.btn-green i {
  margin-right: 10px;
  font-size: 23px;
  position: relative;
  top: 4px;
}
.dashboard .btns-wrapper a.btn.btn-yellow i {
  margin-right: 10px;
  font-size: 23px;
  position: relative;
  top: 4px;
}
/* seller-dashboard css ends here */
.dashboard .recent-orders-wrapper tbody tr:last-child th,
.dashboard .recent-orders-wrapper tbody tr:last-child td {
  border-bottom: 0px;
}
.paginations_results {
  padding-top: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.paginations_results .showing_results p {
  color: #000000;
  font-size: 12px;
  font-family: "Poppins";
  margin: 0 0 15px;
  margin-bottom: 0;
}
.paginations_results .showing_results {
  margin-right: 30px;
}
.paginations_results ul.paginations {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-left: 56px;
}
.paginations_results ul.paginations li {
  display: inline-block;
}
.paginations_results ul.paginations li.arrow {
  border: 1px solid #ababab;
  padding: 5px 15px;
  border-radius: 4px;
  margin: 0 5px;
}
.paginations_results ul.paginations li.arrow i {
  color: #71966d;
  font-size: 21px;
}
.paginations_results ul.paginations li.pagi {
  padding: 0 5px;
  font-size: 12px;
  color: #000000;
  font-family: "Poppins";
}
/* responsive starts here */
@media (max-width: 1366px) {
  .dashboard .btns-wrapper a.btn.btn-green {
    padding: 6px 13px;
  }
}
@media (max-width: 1024px) {
  .seller-tabs-list-wrapper {
    padding: 10px;
  }
  .seller-tabs-list-wrapper ul li a {
    font-size: 13px;
  }
  .seller_sidebar .seller_profile .topbar h4 {
    font-size: 20px;
  }
  .dashboard .btns-wrapper {
    display: block;
  }
  .dashboard .btns-wrapper a.btn.btn-green {
    width: 100%;
  }
  .dashboard .btns-wrapper a.btn.btn-yellow {
    width: 100%;
    margin: 10px 0px;
  }
}

@media (max-width: 992px) {
  .seller_sidebar .sales_summary {
    margin-bottom: 20px;
  }
}
@media (max-width: 576px) {
  .seller-tabs-list-wrapper ul {
    display: block;
  }
  .dashboard .recent-orders-wrapper table.table th.num {
    width: 175px;
  }
  .dashboard .recent-orders-wrapper table.table td {
    width: 175px;
  }
}

/* responsive ends here */
