.subscription {
	padding: 100px 0px;
}
.subscription .heading-wrapper {
	text-align: center;
}

.subscription .heading-wrapper h4 {
	color: #000000;
	font-family: "muli-bold";
	font-size: 35px;
	font-weight: 700;
}

.subscription .heading-wrapper p {
	color: #7f7f7f;
	font-size: 19px;
	margin-top: 20px;
}
.subscription .subscription-card-wrapper .btn-wrapper {
	text-align: center;
	background: #f2f2f2;
	padding-bottom: 30px;
	border-radius: 0px 0px 7px 7px;
}
.subscription .subscription-card-wrapper .heading-wrapper {
	background: #71966d;
	padding: 15px 0px;
	text-align: center;
	border-radius: 7px 7px 0px 0px;
}
.subscription .subscription-card-wrapper .heading-wrapper h6 {
	margin: 0px;
	font-family: "muli-SemiBold";
	color: #ffffff;
	font-size: 21px;
	font-weight: 600;
}
.subscription .subscription-card-wrapper .heading-list-wrapper {
	padding: 33px 45px;
	background: #f2f2f2;
}
.subscription .subscription-card-wrapper .heading-list-wrapper .heading-list {
	text-align: center;
}
.subscription
	.subscription-card-wrapper
	.heading-list-wrapper
	.heading-list
	h2 {
	font-family: "muli-SemiBold";
	font-size: 35px;
	font-weight: 600;
	color: #122000;
}
.subscription
	.subscription-card-wrapper
	.heading-list-wrapper
	.heading-list
	h6 {
	color: #545454;
	font-size: 14px;
	font-family: "muli-reg";
}
.subscription
	.subscription-card-wrapper
	.heading-list-wrapper
	.list-wrapper
	ul
	li {
	font-size: 14px;
	font-family: "muli-reg";
	line-height: 2;
	color: #233a08;
	font-weight: 300;
	list-style: none;
}
.subscription
	.subscription-card-wrapper
	.heading-list-wrapper
	.list-wrapper
	ul
	li::before {
	content: url("../img/checked.png");
	margin-right: 15px;
}
.subscription .subscription-card-wrapper .heading-list-wrapper .list-wrapper {
	margin-top: 31px;
}
.subscription .subscription-card-wrapper .btn-wrapper a.btn {
	background: #71966d;
	width: 200px;
	border-radius: 2px;
	height: 43px;
	color: #ffff;
	font-family: "muli-SemiBold";
	font-size: 16px;
	font-weight: 600;
}
.subscription-slider .slick-active {
	transform: scale(0.8);
}
.subscription-slider .slick-center {
	transform: scale(1);
}
.subscription-slider .slick-dots li button:before {
	color: #71966d;
	opacity: 1;
	font-size: 10px;
}

.subscription-slider .slick-dots li.slick-active button:before {
	color: #71966d;
	opacity: 1;
	font-size: 16px;
}
.subscription-slider .slick-dots li {
	margin: 0px;
}
.subscription-slider .slick-dots li button:before {
	color: #71966d;
	opacity: 1;
	font-size: 10px;
}

.subscription-slider .slick-dots li {
	margin: 0px;
}

.skip-wrapper {
	padding: 37px 0px 50px;
}
.skip-wrapper a.skip {
	background: #bee6ba;
	width: 100%;
	display: block;
	text-align: center;
	padding: 10px 0px;
	border: 1px solid #5f875a;
	border-radius: 4px;
	color: #5f875a;
	text-decoration: none;
}
.subscription-slider .slick-dots {
	position: relative;
	top: 24%;
}
