.pageheader {
	padding: 100px 0px;
	background-image: url("../img/shop-pageheader.png");
	background-repeat: no-repeat;
	background-position: center;
	background-size: 100%;
	height: 60vh;
}
.pageheader h5 {
	font-family: "muli-bold";
	font-size: 17px;
	color: #fff;
}
.pageheader h2 {
	color: #fff;
	font-family: "muli-bold";
	font-size: 53px;
	text-transform: uppercase;
}
.pageheader h6 {
	font-family: "muli-reg";
	color: #fff;
	font-size: 16px;
	text-transform: uppercase;
}
.pageheader h3 {
	font-family: "muli-bold";
	font-size: 48px;
	color: #fff;
	margin: 21px 0px 5px 0px;
}
.pageheader a.btn {
	background: #fab528;
	padding: 8px 25px;
	margin-top: 20px;
	color: #000;
	font-size: 16px;
	font-weight: 700;
}
.product-sec {
	padding: 0px;
}
.product-sec .foods-row h2 {
	font-family: "muli-bold";
	color: #000000;
	font-size: 30px;
	font-weight: 700;
}
.product-sec .foods-row .sort-wrapper ul {
	display: flex;
	padding: 0px;
	justify-content: end;
	list-style: none;
	align-items: center;
    margin-top: 25px;
}
.product-sec .foods-row .sort-wrapper ul li {
	margin: 0px 18px 0px 0px;
}
.product-sec .foods-row .sort-wrapper ul li span {
	font-family: "muli-bold";
	color: #000;
	font-size: 16px;
	text-transform: capitalize;
}
.product-sec .foods-row .sort-wrapper ul li .sort-field-wrapper {
	display: flex;
	align-items: center;
}

.product-sec
	.foods-row
	.sort-wrapper
	ul
	li
	.sort-field-wrapper
	select.form-control {
	width: 240px;
	border: 1px solid #cccccc;
	margin-left: 14px;
	color: #999999;
	height: 44px;
}

.product-sec .foods-row .sort-wrapper ul li .sort-field-wrapper label {
	font-family: "muli-bold";
	color: #000;
	font-size: 16px;
	white-space: nowrap;
}
.product-sec .foods-row .sort-wrapper ul li a i {
	font-size: 20px;
	color: #cccccc;
}
.foods-row {
	padding-bottom: 30px;
}

.sidebar-wrapper .heading-wrapper h4 {
	color: #000000;
	font-size: 18px;
	font-family: "muli-bold";
}
.categories-sidebar-wrapper .accordion-item {
	border: 0px;
	margin: 10px 0px;
}
.categories-sidebar-wrapper .accordion-item button.accordion-button {
	box-shadow: none;
	background: #f3f3f3;
	color: #000;
	font-weight: 600;
	font-family: "muli-SemiBold";
	display: flex;
	border-radius: 5px;
	justify-content: space-between;
	height: 32px;
	width: 80%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.categories-sidebar-wrapper .accordion-item .accordion-body {
	background: transparent;
}

.categories-sidebar-wrapper .accordion-item .accordion-body ul {
	list-style: none;
}

.categories-sidebar-wrapper .accordion-item .accordion-body ul li a {
	color: #8d8d8d;
	text-decoration: none;
	line-height: 2;
}

.categories-sidebar-wrapper
	.accordion-item
	button.accordion-button[aria-expanded="true"] {
	background: #fab528;
}

.categories-sidebar-wrapper .accordion-item button.accordion-button::after {
	filter: invert(1) brightness(0);
}
.cat-name li a {
	text-decoration: none;
	color: #000;
	font-family: "muli-SemiBold";
	font-size: 13px;
	font-weight: 700;
	background: #f3f3f3;
	display: inline-block;
	margin: 4px 0px;
	height: 32px;
	padding: 3px 25px;
	border-radius: 5px;
}

.cat-name ul {
	list-style: none;
	padding: 0px;
}

.cat-name ul li {
	line-height: 2;
}
.categories-sidebar-wrapper {
	padding-top: 30px;
}
.product-card-wrapper .product-content-wrapper h6 {
	color: #666666;
	font-family: "muli-reg";
	font-size: 12px;
}
.product-card-wrapper h5 {
	font-family: "muli-bold";
	color: #010101;
	font-size: 16px;
}
.product-card-wrapper span.ice {
	font-size: 12px;
	font-weight: 300;
	color: #0099ff;
}
.product-card-wrapper .product-content-wrapper .rating-wrapper ul {
	display: flex;
	list-style: none;
	padding: 0px;
}
.product-card-wrapper .product-content-wrapper .rating-wrapper ul li i {
	color: #ffb300;
	font-size: 10px;
}
.product-card-wrapper
	.product-content-wrapper
	.rating-wrapper
	ul
	li.total-rating
	span {
	display: inline-block;
	padding-left: 8px;
	font-family: "muli-reg";
	font-size: 11px;
	color: #999999;
}
.product-card-wrapper .product-content-wrapper h4 {
	color: #669900;
	font-family: "muli-bold";
	font-size: 16px;
	font-weight: 700;
}
.product-card-wrapper .product-content-wrapper h4 strike {
	text-decoration: line-through !important;
	font-size: 12px;
}
.product-sec .product-card-wrapper .product-content-wrapper .btn {
	background: #ed9521;
	width: 100%;
	color: #000;
}
.pagination-wrapper ul {
	padding: 0px;
	list-style: none;
	display: flex;
	justify-content: center;
}
.pagination-wrapper ul li {
	margin: 0px 10px;
}
.pagination-wrapper ul li button {
	background: transparent;
	border: 0px;
	font-size: 24px;
	color: #000;
}
.pagination-wrapper ul li span.current {
	border: 1px solid #cccccc;
	width: 50px;
	display: block;
	text-align: center;
	border-radius: 6px;
	height: 35px;
	line-height: 2;
	font-weight: 700;
}
.pagination-wrapper ul li span.total {
	font-weight: 700;
	line-height: 2;
}
/* RESPONSIVE STARTS HERE */
@media (max-width: 1366px) {
	.pageheader {
		background-size: cover;
	}
}

@media (max-width: 1024px) {
	.categories-sidebar-wrapper .accordion-item .accordion-body ul {
		list-style: none;
		padding: 0px;
	}
	.categories-sidebar-wrapper .accordion-item button.accordion-button {
		font-size: 13px;
		width: 90%;
	}
	.product-card-wrapper h5 {
		font-size: 12px;
	}
	.product-sec .foods-row h2 {
		font-size: 20px;
	}
	.heading-wrapper h4 {
		font-size: 16px;
	}
	.product-sec {
		padding: 50px 0px 0px 0px;
	}

}

@media (max-width: 1024px) {
	.pageheader {
		padding: 100px 0px;
		/* background-image: url(http://localhost:3000/static/media/shop-pageheader.faaadf2805225749faa8.png); */
		background-repeat: no-repeat;
		background-position: center left;
		background-size: 100%;
	}
}

@media (max-width: 992px) {
	.categories-sidebar-wrapper .accordion-item button.accordion-button {
		width: 100%;
	}
	.product-card-wrapper .product-img-wrapper figure {
		width: 100%;
		height: 400px;
		overflow: hidden;
	}
	.product-card-wrapper .product-img-wrapper img {
		width: 100%;
		height: 400px;
		object-fit: cover;
	}
	.product-card-wrapper h5 {
		font-size: 20px;
	}
	.product-card-wrapper {
		margin-bottom: 10px;
	}
}

@media (max-width: 768px) {
	.pageheader{
		height: unset;
	}
	.product-card-wrapper .product-img-wrapper img {
		object-fit: fill;
	}
}

@media (max-width: 567px) {
	.product-sec .foods-row	.sort-wrapper ul li	.sort-field-wrapper	select.form-control {
    width: 185px;
    margin-left: 10px;
    font-size: 12px;
}
.product-sec .foods-row .sort-wrapper ul li {
    margin: 0px 12px 0px 0px;
}
.product-card-wrapper .product-img-wrapper figure {
    height: 300px;
}
.product-card-wrapper .product-img-wrapper img {
    height: 300px;
    object-fit: cover;
}
.product-sec {
    padding: 0;
}
.product-card-wrapper .product-img-wrapper img {
	object-fit: fill;
}
}
@media (max-width: 400px) {
	.product-sec .foods-row	.sort-wrapper ul li	.sort-field-wrapper	select.form-control {
		width: 150px;
		margin-left: 10px;
	}
	.product-sec .foods-row .sort-wrapper ul li .sort-field-wrapper label {
		font-size: 14px;
		display: none;
	}
	.product-sec .foods-row .sort-wrapper ul li span {
		font-size: 14px;
		display: none;
	}
	.product-sec .foods-row .sort-wrapper ul li {
		margin: 0px 15px 0px 0px;
	}
}

@media (max-width: 300px) {
	.product-card-wrapper .product-img-wrapper img {
		object-fit: fill;
	}
	.product-sec .foods-row .sort-wrapper ul li:last-child{
		margin: 0px;
	}
	.product-sec .foods-row .sort-wrapper ul li {
		margin: 0px 6px 0px 0px;
	}
	.product-sec .foods-row .sort-wrapper ul li .sort-field-wrapper label {
		font-size: 12px;
	}
	.product-sec .foods-row .sort-wrapper ul li span {
		font-size: 12px;
	}
	.product-sec .foods-row	.sort-wrapper ul li	.sort-field-wrapper	select.form-control {
		width: 120px;
		margin-left: 5px;
	}
}

@media (max-width: 567px) {
	.pageheader {
		/* padding: 20px 0; */
		padding: 33px 0;
	}
	.product-detail .product-slider-wrapper .main-slider-wrapper {
		height: 270px;
	}
	.pageheader h2 {
		font-size: 40px;
	}
}

@media (max-width: 300px) {
	.product-detail .product-slider-wrapper .main-slider-wrapper {
		height: 180px;
	}
	.product-detail .product-detail-content-wrapper .detail-wrapper h3 {
		font-size: 18px;
	}
	.product-detail .product-detail-content-wrapper .detail-wrapper h4 {
		font-size: 14px;
	}
	.product-detail .seller-card-wrapper img {
		height: 35px;
		width: 35px;
		margin-bottom: 0px;
	}
	.product-detail .seller-card-wrapper .seller-content-wrapper h4 {
		font-size: 13px;
		text-transform: capitalize;
		margin: 0px;
	}
	.product-detail .seller-card-wrapper .seller-content-wrapper h6 {
		margin: 0px;
	}
	.product-detail-content-wrapper
	.quantity-card-wrapper
	.quantity-wrapper
	.quantity-input-wrapper {
    padding: 5px;
}
.product-detail .quantity-card-wrapper .btn-wrapper .btn {
    font-size: 13px;
    margin-left: 10px;
	padding: 11px 10px;
}
.reviews-wrapper .heading-wrapper h5 {
    font-size: 20px;
}
.add-review-wrapper .review-form-wrapper .rate-wrapper label {
    font-size: 11px;
}
}




/* RESPONSIVE ENDS HERE */
