.sellers_orders .dropdown .btn-secondary {
	background: transparent;
	border: 0px;
	padding: 0px;
	color: #000;
	font-family: "muli-reg";
	font-size: 13px;
	font-weight: 300;
}
.dashboard .pus .sellers_orders .table-resposnive th.Stock {
	width: 7%;
}
.dashboard .pus .sellers_orders .table-resposnive td.Available {
	width: 7%;
}
.pus .sellers_orders .table-resposnive td.billingname {
	width: 7%;
}
.pus .sellers_orders .table-resposnive th.billingname {
	width: 7%;
}
.pus .sellers_orders .table-resposnive th.Status {
	width: 5%;
}
.pus .sellers_orders .table-resposnive td.orderid {
	width: 5%;
}
.pus .sellers_orders .table-resposnive th.Details {
	text-align: center;
	width: 8%;
}
.pus .sellers_orders .table-resposnive td.Detailsu {
	text-align: center;
	width: 8%;
}
.pus .sellers_orders .table-resposnive td.Detailsu .btn {
	margin: 0px 0px 0px 10px;
	font-size: 13px;
}
.sellers_orders td.Available ul.dropdown-menu {
	padding: 0px 0px;
	min-width: unset !important;
	font-size: 13px;
}
.dashboard .pus .sellers_orders .table-resposnive td.Available .dropdown-item {
	padding: 7px 9px;
	border-bottom: 1px solid #80808047;
	color: #000000;
	font-size: 12px;
	font-family: "Poppins";
	font-weight: 400;
}
.dashboard
	.pus
	.sellers_orders
	.table-resposnive
	td.Available
	ul.dropdown-menu
	li:last-child
	.dropdown-item {
	border-bottom: 0px;
}
