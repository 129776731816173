.account-form-wrapper .account-heading-wrapper h3 {
  color: #2a263d;
  font-size: 30px;
  font-weight: 700;
}
.account-form-wrapper form {
  margin-top: 30px;
}
.account-form-wrapper form label {
  font-family: "muli-reg";
  color: #2a263d;
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 10px;
}
.account-form-wrapper form input {
  border: 1px solid #b8b8b8;
  border-radius: 0px;
  height: 52px;
  color: #2a263d;
  background: #fff !important;
}
.account-form-wrapper form input[disabled] {
  cursor: no-drop;
}
.account-form-wrapper form .form-group {
  margin-bottom: 30px;
}
.my-profile .account-form-wrapper {
  padding-top: 123px;
}
.my-profile .account-form-wrapper button.btn {
  background: #71966d;
  border-radius: 0px;
  color: #fff;
  padding: 10px 48px;
  height: 49px;
  font-family: "muli-bold";
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
}
.my-profile .col-lg-9 {
  padding-left: 46px;
}


@media (max-width: 576px) {
  .my-profile .col-lg-9 {
    padding-left: 15px;
}
.my-profile .account-form-wrapper {
  padding-top: 30px;
}
}