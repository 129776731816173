.cutomer-res .star-and-date {
  display: flex;
  align-items: center;
}
.cutomer-res .star-and-date ul {
  display: flex;
  list-style: none;
  padding: 0px;
  margin: 0px;
}
.cutomer-res {
  background-color: #f6f6f6;
  padding: 20px;
}
.cutomer-res .star-and-date ul li {
  margin: 0px 2px;
}
.star-and-date span.date {
  color: #999999;
  font-size: 12px;
  margin-left: 10px;
  font-weight: 400;
}
.rev-wrapper .rev-content p {
  color: #343436;
  font-size: 12px;
  font-weight: 400;
  font-family: "muli-reg";
  margin-top: 10px;
}
.rev-content button {
  color: #ed9521;
  font-weight: 500;
  font-size: 13px;
  text-decoration: underline;
  font-family: "Open Sans";
  background: transparent;
  border: none;
  padding: 0px;
  text-decoration: underline !important;
}
.rev-content button:hover {
  background: none;
  border: none;
  box-shadow: none;
  color: #ed9521;
}
.cutomer-res .table thead th {
  color: #000000;
  font-family: "muli-reg";
  font-weight: 600;
  font-size: 11px;
}
.cutomer-res tbody {
  border-top: 0px !important;
}
.cutomer-res .table td {
  color: #000000;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 12px;
  vertical-align: middle;
}
.dashboard .cutomer-res h3 {
  color: #000000;
  font-size: 18px;
  font-family: "Poppins";
  font-weight: 500;
  margin-bottom: 30px;
}
.cutomer-res .table thead th {
  border-top: 0px;
  white-space: nowrap;
}
div#staticBackdrop button.close {
  position: absolute;
  right: 4px;
  color: #000;
  opacity: 1;
  top: 4px;
  z-index: 99999;
  cursor: pointer;
  background: transparent;
  border: 0px;
  font-size: 27px;
}
div#staticBackdrop .modal-body {
  padding: 29px;
}
div#staticBackdrop h4 {
  color: #000000;
  font-size: 23px;
  font-family: "muli-bold";
  font-weight: 700;
  margin-bottom: 20px;
}
div#staticBackdrop .form-control {
  border: 1px solid;
  color: #707070;
  font-family: "Poppins";
  border-radius: 3px;
  padding: 12px;
  border: 2px solid #cbcbcb;
}
div#staticBackdrop button.btn {
  display: block;
  background: #71966d;
  color: #fff;
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 500;
  padding: 9px 26px;
  border: 1px solid;
  transition: all 0.3s ease-in-out;
  height: 42px;
}
div#staticBackdrop textarea.form-control::placeholder {
  color: #787878;
  font-size: 16px;
}
div#staticBackdrop button.btn:hover {
  outline: 1px solid #ed9521;
}
.rev {
  backdrop-filter: blur(9px);
  background-color: #00000000;
}
.ret {
  position: relative;
}
fieldset,
label {
  margin: 0;
  padding: 0;
}

/****** Style Star Rating Widget *****/

.rating {
  border: none;
  float: left;
  position: absolute;
  top: 0px;
  right: 26%;
}

.rating > input {
  display: none;
}
.rating > label:before {
  margin: 5px;
  font-size: 1.25em;
  font-family: FontAwesome;
  display: inline-block;
  content: "\f005";
}

.rating > .half:before {
  content: "\f089";
  position: absolute;
}

.rating > label {
  color: #ddd;
  float: right;
}

/***** CSS Magic to Highlight Stars on Hover *****/

.rating > input:checked ~ label, /* show gold star when clicked */
  .rating:not(:checked) > label:hover, /* hover current star */
  .rating:not(:checked) > label:hover ~ label {
  color: #ffd700;
} /* hover previous stars in list */

.rating > input:checked + label:hover, /* hover current star when changing rating */
  .rating > input:checked ~ label:hover,
  .rating > label:hover ~ input:checked ~ label, /* lighten current selection */
  .rating > input:checked ~ label:hover ~ label {
  color: #ffed85;
}

.cutomer-res thead,
.cutomer-res tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}
.cutomer-res th.product {
  width: 10%;
}
.cutomer-res .table td.pro-img {
  /* width: 10%; */
  width: 55px;
  height: 55px;
  overflow: hidden;
}
.cutomer-res .table td.pro-img img{
  width: 55px;
  height: 55px;
}
.cutomer-res th.product-name {
  width: 18%;
}
.cutomer-res .table td.pro-name {
  width: 18%;
  font-family: "muli-reg";
  font-size: 11px;
  font-weight: 400;
}
.cutomer-res .table thead th.customer-detail {
  width: 30%;
}
.cutomer-res .table td.pro-details {
  width: 30%;
  font-family: "muli-reg";
  font-size: 11px;
  font-weight: 400;
}
.cutomer-res .table thead th.review-rating {
  width: 50%;
}
.cutomer-res .table td.pro-reviews {
  width: 50%;
}
.cutomer-res tbody {
  border-top: 0px !important;
}


@media (max-width: 1024px) {
  .cutomer-res .table td.pro-details {
    width: 45%;
}
}

@media (max-width: 576px) {
	.cutomer-res .table td.pro-img {
    width: 100px;
  }
  .cutomer-res .table td.pro-name {
    width: 150px;
}
  .cutomer-res .table td.pro-details {
    width: 200px;
  }
  .cutomer-res .table td.pro-reviews {
    width: 500px;
}
.paginations_results {
  display: block;
}
.paginations_results ul.paginations {
  margin-left: 0px;
}
}