.my-profile .order-request-wrapper {
  padding-top: 125px;
}
.my-profile .order-request-wrapper .order-heading-wrapper h3 {
  color: #2a263d;
  font-family: "muli-bold";
  font-size: 30px;
  font-weight: 700;
}
.my-profile .order-request-wrapper .order-heading-wrapper {
  margin-bottom: 30px;
}
.my-profile .order-request-wrapper .order-detail-wrapper {
  padding: 30px 40px;
  background: #f9f9f9;
}
.my-profile .order-request-wrapper .order-detail-wrapper .order-no-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 20px;
}
.my-profile .order-request-wrapper .order-detail-wrapper .order-no-wrapper h5 {
  font-family: "muli-bold";
  font-size: 23px;
  font-weight: 700;
  color: #2a263d;
}
.my-profile .order-request-wrapper .order-detail-wrapper .order-no-wrapper h6 {
  font-family: "muli-bold";
  font-size: 18px;
  font-weight: 700;
  color: #2a263d;
}
.my-profile .order-request-wrapper .order-detail-wrapper .order-cards-wrapper {
  background: #fff;
  padding: 20px;
  box-shadow: -7px 8px 17px -6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}
.my-profile
  .order-request-wrapper
  .order-detail-wrapper
  .order-cards-wrapper
  .card-heading-wrapper
  h5 {
  font-family: "muli-bold";
  font-size: 20px;
  font-weight: 700;
  color: #2a263d;
}
.my-profile
  .order-request-wrapper
  .order-detail-wrapper
  .order-cards-wrapper
  .cards-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-top: 27px;
}
.my-profile
  .order-request-wrapper
  .order-detail-wrapper
  .order-cards-wrapper
  .cards-wrapper
  .order-card-wrapper {
  flex: 0 0 50%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.my-profile
  .order-request-wrapper
  .order-detail-wrapper
  .order-cards-wrapper
  .cards-wrapper
  .order-card-wrapper
  .order-img-wrapper {
  flex: 0 0 30%;
}
.my-profile
  .order-request-wrapper
  .order-detail-wrapper
  .order-cards-wrapper
  .cards-wrapper
  .order-card-wrapper
  .order-img-wrapper
  figure {
  margin: 0px;
  height: 100px;
  width: 88px;
  overflow: hidden;
  border-radius: 8px;
}
.my-profile
  .order-request-wrapper
  .order-detail-wrapper
  .order-cards-wrapper
  .cards-wrapper
  .order-card-wrapper
  .order-img-wrapper
  figure
  img {
    height: 100px;
    width: 88px;
  /* object-fit: cover; */
  /* border-radius: 6px; */
}
.my-profile
  .order-request-wrapper
  .order-detail-wrapper
  .order-cards-wrapper
  .cards-wrapper
  .order-card-wrapper
  .order-content-wrapper {
  flex: 0 0 70%;
  padding: 0px 30px 0px 0px;
}
.my-profile
  .order-request-wrapper
  .order-detail-wrapper
  .order-cards-wrapper
  .cards-wrapper
  .order-card-wrapper
  .order-content-wrapper
  .content-heading-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.my-profile
  .order-request-wrapper
  .order-detail-wrapper
  .order-cards-wrapper
  .cards-wrapper
  .order-card-wrapper
  .order-content-wrapper
  .order-size-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-bottom: 5px;
}
.my-profile
  .order-request-wrapper
  .order-detail-wrapper
  .order-cards-wrapper
  .cards-wrapper
  .order-card-wrapper
  .order-content-wrapper
  a.btn {
  background: #9a3407;
  border-radius: 3px;
  font-family: "muli-bold";
  font-size: 12px;
  color: #ffff;
  padding: 9px 22px;
  text-transform: uppercase;
}
.my-profile
  .order-request-wrapper
  .order-detail-wrapper
  .order-cards-wrapper
  .cards-wrapper
  .order-card-wrapper
  .order-content-wrapper
  .content-heading-wrapper
  h6 {
  font-size: 18px;
  font-family: "muli-reg";
  color: #1f2c35;
  font-weight: 400;
  margin-bottom: 10px;
}
.my-profile
  .order-request-wrapper
  .order-detail-wrapper
  .order-cards-wrapper
  .cards-wrapper
  .order-card-wrapper
  .order-content-wrapper
  .content-heading-wrapper
  span {
  color: #858585;
  font-size: 15px;
  font-weight: 600;
  font-family: "muli-SemiBold";
  line-height: 1;
  margin-bottom: 10px;
}
.my-profile
  .order-request-wrapper
  .order-detail-wrapper
  .order-cards-wrapper
  .cards-wrapper
  .order-card-wrapper
  .order-content-wrapper
  .order-size-wrapper
  span.size {
  color: #858585;
  font-size: 15px;
  font-weight: 600;
  font-family: "muli-SemiBold";
  border-right: 2px solid #b2b2b2;
  padding-right: 19px;
}
.my-profile
  .order-request-wrapper
  .order-detail-wrapper
  .order-cards-wrapper
  .cards-wrapper
  .order-card-wrapper
  .order-content-wrapper
  .order-size-wrapper
  h5.price {
  color: #71966d;
  font-size: 18px;
  font-weight: 700;
  font-family: "muli-bold";
  padding-left: 20px;
  margin-bottom: 0;
}
.order-detail-wrapper .shipping-address-wrapper {
  background: #fff;
  padding: 20px 21px;
  box-shadow: -7px 8px 17px -6px rgb(0 0 0 / 30%);
  border-radius: 10px;
  margin-top: 30px;
}
.order-detail-wrapper .shipping-address-wrapper .heading-wrapper h5 {
  font-family: "muli-bold";
  font-size: 20px;
  font-weight: 700;
  color: #2a263d;
}
.order-request-wrapper .shipping-lists-wrapper {
  display: flex;
  align-items: baseline;
  margin-top: 18px;
}
.order-request-wrapper
  .shipping-lists-wrapper
  .single-ship-wrapper:first-child {
  flex: 0 0 59%;
}
.order-request-wrapper .shipping-lists-wrapper .single-ship-wrapper:last-child {
  flex: 0 0 40%;
}
.order-request-wrapper .shipping-lists-wrapper .single-ship-wrapper ul {
  list-style: none;
  padding: 0px;
  margin: 0px;
}
.order-request-wrapper .shipping-lists-wrapper .single-ship-wrapper ul li {
  display: flex;
}
.order-request-wrapper
  .shipping-lists-wrapper
  .single-ship-wrapper
  ul
  li
  span.property {
  flex: 0 0 21%;
}
.order-request-wrapper .shipping-lists-wrapper .single-ship-wrapper ul li span {
  font-family: "muli-reg";
  font-size: 18px;
  font-weight: 400;
}
.order-request-wrapper .shipping-lists-wrapper .single-ship-wrapper ul li {
  line-height: 1.89;
}
.my-profile
  .order-request-wrapper
  .order-detail-wrapper
  .order-cards-wrapper
  .cards-wrapper
  .order-card-wrapper:first-child {
  border-right: 1px solid #b3b3b3;
}
.my-profile
  .order-request-wrapper
  .order-detail-wrapper
  .order-cards-wrapper
  .cards-wrapper
  .order-card-wrapper:nth-child(2) {
  padding-left: 18px;
}
.order-request-wrapper .invoice-wrapper {
  background: #fff;
  padding: 20px 21px;
  box-shadow: -7px 8px 17px -6px rgb(0 0 0 / 30%);
  border-radius: 10px;
  margin-top: 30px;
}
.order-request-wrapper .invoice-wrapper .invoice-heading-wrapper h5 {
  font-family: "muli-bold";
  font-size: 20px;
  font-weight: 700;
  color: #2a263d;
}
.order-request-wrapper .invoice-wrapper .invoice-list-wrapper ul {
  list-style: none;
  padding: 0px;
  margin: 0;
  padding-bottom: 22px;
}
.order-request-wrapper .invoice-wrapper .invoice-list-wrapper ul li {
  display: flex;
  justify-content: space-between;
  line-height: 2.1;
}
.order-request-wrapper
  .invoice-wrapper
  .invoice-list-wrapper
  ul
  li
  span.property {
  color: #000000;
  font-size: 18px;
  font-family: "muli-reg";
  font-weight: 400;
}
.order-request-wrapper .invoice-wrapper .invoice-list-wrapper ul li span.value {
  color: #000000;
  font-size: 18px;
  font-family: "muli-bold";
  font-weight: 700;
}
.order-request-wrapper .invoice-wrapper .total-price-wrapper ul {
  list-style: none;
  padding: 0px;
  margin: 0px;
}
.order-request-wrapper .invoice-wrapper .total-price-wrapper ul li {
  display: flex;
  justify-content: space-between;
  line-height: 2.1;
}
.order-request-wrapper
  .invoice-wrapper
  .total-price-wrapper
  ul
  li
  span.property {
  color: #000000;
  font-size: 18px;
  font-family: "muli-bold";
  font-weight: 700;
}
.order-request-wrapper .invoice-wrapper .total-price-wrapper ul li span.value {
  color: #000000;
  font-size: 18px;
  font-family: "muli-bold";
  font-weight: 700;
}
.total-price-wrapper {
  border-top: 1px solid #707070;
  padding: 10px 0px;
}
.order-request-wrapper .back-btn-wrapper a {
  background: #fab528;
  padding: 10px 42px;
  border-radius: 0px;
  font-weight: 700;
  font-family: "muli-bold";
  color: #000;
  font-size: 14px;
  text-transform: uppercase;
}
.order-detail-wrapper .back-btn-wrapper {
  text-align: end;
  padding-top: 30px;
}

@media (max-width: 576px) {
  .my-profile .order-request-wrapper .order-detail-wrapper {
      padding: 20px;
  }
  .my-profile
.order-request-wrapper
.order-detail-wrapper
.order-cards-wrapper
.cards-wrapper {
  display: block;
}
.my-profile
.order-request-wrapper
.order-detail-wrapper
.order-cards-wrapper
.cards-wrapper
.order-card-wrapper
.order-img-wrapper
figure {
  margin: 0px;
  height: 100px;
  width: 88px;
  overflow: hidden;
  border-radius: 8px;
}
.my-profile
  .order-request-wrapper
  .order-detail-wrapper
  .order-cards-wrapper
  .cards-wrapper
  .order-card-wrapper {
    flex: 0 0 50%;
    display: block;
    flex-wrap: wrap;
}
.my-profile
  .order-request-wrapper
  .order-detail-wrapper
  .order-cards-wrapper
  .cards-wrapper
  .order-card-wrapper
  .order-content-wrapper
  .content-heading-wrapper {
    display: block;
    margin-top: 10px;
}
.my-profile
  .order-request-wrapper
  .order-detail-wrapper
  .order-cards-wrapper
  .cards-wrapper
  .order-card-wrapper
  .order-content-wrapper
  .order-size-wrapper {
    display: block;
    padding: 5px 0px;
}
.my-profile
  .order-request-wrapper
  .order-detail-wrapper
  .order-cards-wrapper
  .cards-wrapper
  .order-card-wrapper
  .order-content-wrapper
  .order-size-wrapper
  span.size {
    border-right: 0;
    padding-bottom: 5px;
    display: block;
}
.my-profile
  .order-request-wrapper
  .order-detail-wrapper
  .order-cards-wrapper
  .cards-wrapper
  .order-card-wrapper:first-child {
    border-right: 0px;
}
.my-profile
  .order-request-wrapper
  .order-detail-wrapper
  .order-cards-wrapper
  .cards-wrapper
  .order-card-wrapper
  .order-content-wrapper
  .order-size-wrapper
  h5.price {
    padding-left: 0px;
    margin-bottom: 0;
}
.my-profile
  .order-request-wrapper
  .order-detail-wrapper
  .order-cards-wrapper
  .cards-wrapper
  .order-card-wrapper:nth-child(2) {
    padding-left: 0px;
    margin: 30px 0px;
}
.order-request-wrapper .shipping-lists-wrapper {
  display: block;
}
.order-request-wrapper .shipping-lists-wrapper .single-ship-wrapper ul li span {
  font-size: 14px;
  font-weight: 400;
}
.order-request-wrapper
  .invoice-wrapper
  .invoice-list-wrapper
  ul
  li
  span.property {
    font-size: 14px;
}
.order-request-wrapper .invoice-wrapper .invoice-list-wrapper ul li span.value {
  font-size: 14px;
}
.order-request-wrapper
  .invoice-wrapper
  .total-price-wrapper
  ul
  li
  span.property {
    font-size: 16px;
}
.order-request-wrapper .invoice-wrapper .total-price-wrapper ul li span.value {
  font-size: 16px;
}
.logout-wrapper button.logout-btn {
  width: 100%;
  margin-top: 15px;
}
.my-profile .order-request-wrapper {
  padding-top: 35px;
}
.my-profile .order-request-wrapper .order-heading-wrapper h3 {
  font-size: 26px;
  font-weight: 700;
}
.my-profile .profile-heading-wrapper h2 {
  font-size: 36px;
}
}